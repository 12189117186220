import React from "react";

const Modal = ({ isOpen, children }) => {
  if (!isOpen) return null; // Do not render if the modal is closed

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-sm max-w-4xl w-full relative">
        {children}
      </div>
    </div>
  );
};

export default Modal;
