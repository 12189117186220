export const experiences = [
  {
    duration: "Dec 2017 - Present",
    company: "Icoderz",
    location: "Ahmedabad, India",
    role: "Crisis Intervention Specialist",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    skills: [
      { skill: "JavaScript", value: 6, years: 3, skillsUsed: "Last Month" },
      { skill: "React.js", value: 8, years: 3, skillsUsed: "Current" },
      { skill: "MySQL", value: 6, years: 3, skillsUsed: "Months ago" },
      { skill: "RESTful", value: 4, years: 3, skillsUsed: "This Week" },
    ],
  },
  {
    duration: "Jan 2015 - Nov 2017",
    company: "TechCorp",
    location: "Mumbai",
    role: "Full Stack Developer",
    description:
      "Developed full-stack applications, and worked on database management. lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorelorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorelorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lore",
    skills: [
      { skill: "JavaScript", value: 6, years: 3, skillsUsed: "Last Month" },
      { skill: "React.js", value: 8, years: 3, skillsUsed: "Current" },
      { skill: "MySQL", value: 6, years: 3, skillsUsed: "Months ago" },
      { skill: "RESTful", value: 4, years: 3, skillsUsed: "This Week" },
    ],
  },
  {
    duration: "Jun 2012 - Dec 2014",
    company: "Innovate Solutions",
    location: "Pune",
    role: "Software Engineer",
    description:
      "Worked on various software projects, and improved UI/UX design. lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorelorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lore",
    skills: [
      { skill: "JavaScript", value: 6, years: 3, skillsUsed: "Last Month" },
      { skill: "React.js", value: 8, years: 3, skillsUsed: "Current" },
      { skill: "MySQL", value: 6, years: 3, skillsUsed: "Months ago" },
      { skill: "RESTful", value: 4, years: 3, skillsUsed: "This Week" },
    ],
  },
];

export const skillsData = [
  {
    skill: "JavaScript",
    level: 6,
    experience: 9,
    lastUsed: "Current",
    isSaved: true,
  },
  {
    skill: "React.js",
    level: 8,
    experience: 5,
    lastUsed: "Current",
    isSaved: true,
  },
  {
    skill: "RESTful",
    level: 5,
    experience: 4,
    lastUsed: "Current",
    isSaved: true,
  },
  {
    skill: "MySQL",
    level: 7,
    experience: 5,
    lastUsed: "1 week ago",
    isSaved: true,
  },
  {
    skill: "Python",
    level: 9,
    experience: 5,
    lastUsed: "1 week ago",
    isSaved: true,
  },
  {
    skill: "Wireframing",
    level: 5,
    experience: 8,
    lastUsed: "1 month ago",
    isSaved: true,
  },
  {
    skill: "Mockups",
    level: 5,
    experience: 6,
    lastUsed: "1 month ago",
    isSaved: true,
  },
  {
    skill: "JavaScript",
    level: 6,
    experience: 9,
    lastUsed: "Current",
    isSaved: true,
  },
  {
    skill: "React.js",
    level: 8,
    experience: 5,
    lastUsed: "Current",
    isSaved: true,
  },
  {
    skill: "RESTful",
    level: 5,
    experience: 4,
    lastUsed: "Current",
    isSaved: true,
  },
  {
    skill: "MySQL",
    level: 7,
    experience: 5,
    lastUsed: "1 week ago",
    isSaved: true,
  },
  {
    skill: "Python",
    level: 9,
    experience: 5,
    lastUsed: "1 week ago",
    isSaved: true,
  },
  {
    skill: "Wireframing",
    level: 5,
    experience: 8,
    lastUsed: "1 month ago",
    isSaved: true,
  },
  {
    skill: "Mockups",
    level: 5,
    experience: 6,
    lastUsed: "1 month ago",
    isSaved: true,
  },
];
