import { useNavigate } from "react-router-dom";
import ChevronLeftIcon from "../../assets/svg/ChevronLeftIcon";
import ChevronDown from "../../assets/svg/ChevronDown";
import JobCard from "./components/JobCard";
import ClipboardIcon from "../../assets/svg/ClipboardIcon";
import ApplyIcon from "../../assets/svg/ApplyIcon";
import ShareIcon from "../../assets/svg/ShareIcon";
import ShareArrowIcon from "../../assets/svg/ShareArrowIcon";
import ReferIcon from "../../assets/svg/ReferIcon";
import TrackIcon from "../../assets/svg/TrackIcon";

const JobDetailPage = () => {
  const navigate = useNavigate();
  const onBackPress = () => {
    navigate(-1);
  };
  return (
    <div className="flex flex-col rounded-lg bg-white w-full flex-grow p-4 cursor-pointer">
      <button className="flex items-center  self-start" onClick={onBackPress}>
        <ChevronLeftIcon className={"w-4 h-4"} />
        <label className="text-xs font-medium text-primary cursor-pointer">
          Back to listing
        </label>
      </button>
      {/* Heading container */}
      <div
        className={`flex flex-col gap-4 justify-around border rounded-md mt-4 overflow-hidden bg-[url(/src/assets/images/assessmentBg.png)] bg-no-repeat bg-center p-3`}
      >
        <div className="flex items-center w-full gap-3">
          <div className="rounded-md bg-white flex-1">
            <input
              className="bg-transparent px-3 h-8 text-xs font-medium w-full"
              placeholder="Search by job titles or keyword..."
            />
          </div>
          <div className="rounded-md bg-white flex-1">
            <input
              className="bg-transparent px-3 h-8 text-xs font-medium w-full"
              placeholder="Search by job titles or keyword..."
            />
          </div>
          <div className="rounded-md bg-white flex-1">
            <input
              className="bg-transparent px-3 h-8 text-xs font-medium w-full"
              placeholder="Search by job titles or keyword..."
            />
          </div>
          <button className="font-medium text-sm h-8 rounded-md bg-white px-5 text-[#4d4d4d]">
            Search
          </button>
        </div>
        <div className="flex items-center gap-2">
          <div className="flex items-center border border-white rounded-md text-white text-xs font-semibold py-1 px-2 gap-6">
            <span>Full Time</span>
            <ChevronDown className={"w-5 h-5"} fill={"white"} />
          </div>

          <div className="flex items-center border border-white rounded-md text-white text-xs font-semibold py-1 px-2 gap-6">
            <span>Paying $0</span>
            <ChevronDown className={"w-5 h-5"} fill={"white"} />
          </div>

          <div className="flex items-center border border-white rounded-md text-white text-xs font-semibold py-1 px-2 gap-6">
            <span>to $350</span>
            <ChevronDown className={"w-5 h-5"} fill={"white"} />
          </div>

          <div className="flex items-center border border-white rounded-md text-white text-xs font-semibold py-1 px-2 gap-6">
            <span>Listed Anytime</span>
            <ChevronDown className={"w-5 h-5"} fill={"white"} />
          </div>
        </div>
      </div>

      <div className="flex flex-1 flex-col max-h-fit overflow-y-scroll no-scrollbar px-10 py-5 gap-3.5 w-full">
        <div className="flex w-full gap-4">
          {/* Left section */}
          <div className="flex flex-col flex-1">
            <span className="font-medium text-light-black text-xs items-center flex">
              Body Corporate Brokers •{" "}
              <span className="font-semibold text-xss">&nbsp;11h ago</span>
            </span>
            <span className="font-semibold text-primary my-2">
              Junior IT Analyst
            </span>
            <div className="flex flex-col">
              <span className="font-medium text-primary text-sm">
                Sydney NSW
              </span>
              <span className="font-medium text-primary text-sm">
                Help Desk & IT Support •
                <span className="text-xs">
                  &nbsp;(Information & Communication Technology)
                </span>
              </span>
            </div>

            <div className="my-3">
              <span className="font-inter font-semibold text-primary text-sm">
                Description
              </span>
              <p className="text-light-black text-sm mt-2">
                Collaborate with product management and engineering to define
                and implement innovative solutions for the product direction,
                visuals and experience. Execute all visual design stages from
                concept to final hand-off to engineering. Conceptualize original
                ideas that bring simplicity and user friendliness to complex
                design roadblocks. Create wireframes, storyboards, user flows,
                process flows and site maps to effectively communicate
                interaction and design ideas. Present and defend designs and key
                milestone deliverables to peers and executive level
                stakeholders. Conduct user research and evaluate user feedback.
              </p>
            </div>

            <div className="my-3">
              <span className="font-inter font-semibold text-primary text-sm">
                Responsibilities
              </span>
              <p className="text-light-black text-sm mt-2">
                Identify problems based on the product vision / requirements and
                come up with delightful design solutions & deliverables.
              </p>

              <p className="text-light-black text-sm mt-2">
                Identify problems based on the product vision / requirements and
                come up with delightful design solutions & deliverables.
              </p>

              <p className="text-light-black text-sm mt-2">
                Identify problems based on the product vision / requirements and
                come up with delightful design solutions & deliverables.
              </p>
            </div>
          </div>
          {/* Action section */}
          <div className="flex flex-col w-52 h-full border-l pl-6">
            {/*Button list container */}
            <div className="flex flex-col gap-3">
              <button className="flex justify-center items-center font-semibold text-xs text-primary rounded-lg bg-[#FFCC00] h-8 w-32 items-center gap-2">
                <ApplyIcon className="w-4 h-4" />
                Apply Now
              </button>

              <button className="flex justify-center items-center font-semibold text-xs text-primary rounded-lg border-2 h-8 w-32 items-center gap-2">
                <ShareArrowIcon className="w-4 h-4" />
                Share Job
              </button>

              <button className="flex justify-center items-center font-semibold text-xs text-primary rounded-lg border-2 h-8 w-32 items-center gap-2">
                <ReferIcon className="w-7 h-7" />
                Refer Job
              </button>

              <button className="flex justify-center items-center font-semibold text-xs text-primary rounded-lg border-2 h-8 w-32 items-center gap-2">
                <TrackIcon className="w-4 h-4" />
                Track Job
              </button>
            </div>

            <div className="flex flex-col mt-4 gap-1">
              <span className="font-medium text-xss text-light-black">
                You have <span className="font-semibold">applied</span> for this
                job
              </span>
              <span className="text-xs text-primary font-medium">
                15/10/2024
              </span>
            </div>

            <div className="flex flex-col mt-4 gap-1">
              <span className="font-medium text-xss text-light-black">
                You have <span className="font-semibold">tracked</span> for this
                job
              </span>
              <span className="text-xs text-primary font-medium">
                15/10/2024
              </span>
            </div>

            <div className="flex flex-col mt-4 gap-1">
              <span className="font-medium text-xss text-light-black">
                <span className="font-semibold">Job shared</span> to
              </span>
              <div className="flex items-center gap-4">
                <span className="text-xs text-primary font-medium">
                  John Doe
                </span>
                <span className="text-xs text-primary">15/10/2024</span>
              </div>

              <div className="flex items-center gap-4">
                <span className="text-xs text-primary font-medium">Andrew</span>
                <span className="text-xs text-primary">15/10/2024</span>
              </div>

              <div className="flex items-center gap-4">
                <span className="text-xs text-primary font-medium">
                  Lionel Messi
                </span>
                <span className="text-xs text-primary">15/10/2024</span>
              </div>
            </div>

            <div className="flex flex-col mt-4 gap-1">
              <span className="font-medium text-xss text-light-black">
                <span className="font-semibold">Job referred</span> to
              </span>
              <div className="flex items-center gap-4">
                <span className="text-xs text-primary font-medium">
                  John Doe
                </span>
                <span className="text-xs text-primary">15/10/2024</span>
              </div>

              <div className="flex items-center gap-4">
                <span className="text-xs text-primary font-medium">Andrew</span>
                <span className="text-xs text-primary">15/10/2024</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobDetailPage;
