import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Controller, useForm } from "react-hook-form";
import QuillEditor from "../../../components/CreateQuestion/Question/QuillEditor";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import { SkillInput } from "../../ProfilePage/Components/Skills";
import CustomInput from "../../../components/CustomInput/CustomInput";
import moment from "moment/moment";
import {
  addCandidate,
  getCandidateDetails,
} from "../../../endpoints/candidate.service";
import { useSnackbar } from "notistack";
import {
  addExperience,
  getExperienceList,
  updateExperience,
} from "../../../endpoints/experience.service";
import { skillsUsed } from "../../../utils/constants";

const SkillItem = ({
  skillData,
  workExperienceId,
  customSkillId,
  setExperiences,
}) => {
  const { title, rating, isEditing, lastUsed, years } = skillData;
  const [editable, setEditable] = useState(false);
  useEffect(() => {
    setEditable(isEditing ?? false);
  }, [isEditing]);

  return (
    <>
      {!editable ? (
        <div>
          <div className="flex justify-between items-center grid-cols-4 mb-2">
            <span className="flex-1 font-medium text-primary text-xs">
              {title}
            </span>
            <ProgressBar value={rating} total={10} />
            <span className="flex-1 font-medium text-primary text-center text-xs">
              {years}
            </span>
            <span className="flex-1 font-medium text-primary text-xs text-center">
              {Object.keys(skillsUsed).find(
                (key) => skillsUsed[key] === lastUsed
              )}
            </span>
          </div>
          {/* {index < skills.length - 1 && <Divider className="bg-[#DDDDDD]" />} */}
        </div>
      ) : (
        <SkillInput
          workExperienceId={workExperienceId}
          customSkillId={customSkillId}
          editSkill={customSkillId}
          deleteSkill={customSkillId}
          setEditable={setEditable}
          setExperiences={setExperiences}
        />
      )}
    </>
  );
};

const ExperienceSection = forwardRef(
  ({ experienceData, editable, handleSave }, ref) => {
    const {
      jobTitle,
      startDate,
      endDate,
      isCurrent,
      companyName,
      location,
      jobDescription,
    } = experienceData;
    const {
      handleSubmit,
      control,
      setValue,
      reset,
      formState: { errors },
    } = useForm({
      // defaultValues: {
      //   startDate: moment(startDate).format("YYYY-MM-DD"),
      //   endDate: moment(endDate).format("YYYY-MM-DD"),
      //   jobTitle: jobTitle,
      //   companyName: companyName,
      //   location: location,
      //   jobDescription: jobDescription,
      // },
    });

    const [currentlyWorking, setCurrentlyWorking] = useState(
      isCurrent != undefined ? isCurrent : false
    );

    useImperativeHandle(ref, () => ({
      submit: handleSubmit(handleSave),
      reset: () => reset(),
    }));

    useEffect(() => {
      getCandidateDetails();
      if (currentlyWorking) {
        setValue("endDate", null);
      } else {
        setValue("endDate", moment().format("YYYY-MM-DD"));
      }
    }, [currentlyWorking]);

    useEffect(() => {
      Object.entries(experienceData)?.forEach(([key, value]) => {
        setValue(key, value);
      });
    }, [experienceData]);

    return (
      <div className="w-full">
        {!editable ? (
          <div className="flex justify-between">
            <div className="flex flex-col gap-0.5">
              <span className="font-montserrat text-primary font-bold text-sm">
                {moment(startDate).format("MMM YYYY")} -{" "}
                {isCurrent ? "Present" : moment(endDate).format("MMM YYYY")}
              </span>
              <span className="font-montserrat text-primary font-semibold text-sm">
                {jobTitle}, {companyName}
              </span>
              <span className="font-montserrat text-primary text-xs">
                {location}
              </span>
              <span
                className="font-montserrat text-primary font-medium text-xs text-justify leading-5"
                dangerouslySetInnerHTML={{ __html: jobDescription }}
              />
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-4 mb-3">
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                checked={currentlyWorking}
                onChange={(e) => setCurrentlyWorking(e?.target?.checked)}
              />
              <label className="text-primary text-xs font-medium">
                Currently working for this role
              </label>
            </div>
            <div className="grid grid-cols-2 gap-5">
              <div className="flex flex-col gap-3">
                <CustomInput
                  type="date"
                  label={"Start Date"}
                  name={"startDate"}
                  rules={{ required: "This field is required." }}
                  control={control}
                  errors={errors}
                />

                <CustomInput
                  type="text"
                  label={"Job Title"}
                  name={"jobTitle"}
                  placeholder={"Enter your Job Title"}
                  rules={{ required: "This field is required." }}
                  control={control}
                  errors={errors}
                />
                <CustomInput
                  label={"Location"}
                  name={"location"}
                  rules={{
                    required: "This field is required.",
                  }}
                  placeholder={"Ex: London"}
                  control={control}
                  errors={errors}
                />
              </div>
              <div className="flex flex-col gap-3">
                <div
                  className={`${
                    currentlyWorking ? "opacity-20" : "opacity-100"
                  }`}
                >
                  <CustomInput
                    type="date"
                    label={"End Date"}
                    name={"endDate"}
                    rules={{
                      required: currentlyWorking
                        ? false
                        : "This field is required.",
                    }}
                    control={control}
                    errors={errors}
                  />
                </div>

                <CustomInput
                  type="text"
                  label={"Company Name"}
                  name={"companyName"}
                  placeholder={"Enter your Company Name"}
                  rules={{ required: "This field is required." }}
                  control={control}
                  errors={errors}
                />
              </div>
            </div>
            <div className="mr-4">
              <Controller
                name={"jobDescription"}
                control={control}
                rules={{
                  required: "This field is required.",
                }}
                render={({ field }) => {
                  return (
                    <div className="flex flex-col gap-1">
                      <span className="font-montserrat text-primary font-medium text-xs mb-2">
                        Job Description
                        <span className="font-montserrat text-primary font-medium text-xs text-red-500">
                          *
                        </span>
                      </span>
                      <QuillEditor {...field} />
                      {errors["jobDescription"]?.message && (
                        <span className="whitespace-pre text-xs mt-2 text-orange-700">
                          {errors["jobDescription"]?.message}
                        </span>
                      )}
                    </div>
                  );
                }}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
);

const ExperienceBlock = ({ customExpId, setExperiences, experienceData }) => {
  const { isCurrent, skills, isEditing, _id } = experienceData;
  const experienceFormRef = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const [editable, setEditable] = useState(false); // State for edit mode
  const [tempSkills, setTempSkills] = useState([]);
  useEffect(() => {
    setEditable(isEditing ?? false);
  }, [isEditing]);

  useEffect(() => {
    setTempSkills(
      skills?.length
        ? skills
        : [
            {
              isEditing: true,
              title: "asdfsd",
              years: "",
              skillsUsed: "",
            },
          ]
    );
  }, [skills]);

  const handleEditToggle = (data) => {
    if (editable) {
      let payload = data;
      if (!data?.endDate) {
        delete payload.endDate;
        payload.isCurrent = true;
      }
      if (_id) {
        payload.id = _id;
        updateExperience(payload).then((response) => {
          const { success, data } = response;
          if (success) {
            setExperiences((prev) => {
              return prev?.map((exp) => {
                if (exp?._id === _id) {
                  return { isEditing: false, ...data };
                } else {
                  return exp;
                }
              });
            });
            experienceFormRef?.current?.reset({
              jobTitle: "",
              companyName: "",
              location: "",
              startDate: "",
              endDate: "",
              jobDescription: "",
            });
            enqueueSnackbar("Experience updated successfully.", {
              variant: "success",
            });
          }
        });
      } else {
        addExperience(payload).then((response) => {
          const { success, data } = response;
          if (success) {
            setExperiences((prev) => {
              return prev?.map((exp, index) => {
                if (index === customExpId) {
                  return { isEditing: false, ...data };
                } else {
                  return exp;
                }
              });
            });
            experienceFormRef?.current?.reset({
              jobTitle: "",
              companyName: "",
              location: "",
              startDate: "",
              endDate: "",
              jobDescription: "",
            });
            enqueueSnackbar("Experience added successfully.", {
              variant: "success",
            });
          }
        });
      }
    }
  };

  const handleFormData = (data) => {
    handleEditToggle(data);
  };

  const handleSave = () => {
    if (editable) {
      experienceFormRef?.current?.submit();
    } else {
      setEditable(true);
    }
  };

  const handleAddSkill = () => {
    setTempSkills((prev) => {
      return [
        {
          customId: prev?.length + 1,
          isEditing: true,
          title: "asdfsd",
          years: "",
          skillsUsed: "",
        },
        ...prev,
      ];
    });
  };

  return (
    <div className={`flex flex-col ${editable && "border rounded-lg p-4"}`}>
      <p
        className="text-[#6f6f6f] text-xs font-medium cursor-pointer flex justify-end"
        onClick={handleSave} // Toggle editing state
      >
        {editable ? (_id ? "Update" : "Save") : "Edit"}
      </p>

      <ExperienceSection
        ref={experienceFormRef}
        experienceData={experienceData}
        editable={editable}
        handleSave={handleFormData}
      />

      {skills?.length || editable ? (
        <div className="flex flex-col w-full">
          <div className="flex flex-col">
            <div className="flex mb-5 grid-cols-4">
              <span className="font-montserrat text-primary font-semibold text-sm flex-1">
                Skills Used
              </span>
              <span className="font-montserrat text-primary font-semibold flex-1 text-sm">
                Experience Level
              </span>
              <span className="font-montserrat text-primary font-semibold flex-1 text-sm text-center">
                Years
              </span>
              <span className="font-montserrat text-primary font-semibold flex-1 text-sm text-center">
                Skills Used
              </span>
            </div>
            <div className="flex flex-col gap-4">
              {tempSkills.map((skill, index) => (
                <SkillItem
                  key={skill?._id ?? skill?.customId}
                  skillData={skill}
                  workExperienceId={_id}
                  customSkillId={skill?._id ?? skill?.customId}
                  setExperiences={setExperiences}
                />
              ))}
            </div>
            {editable ? (
              <button
                onClick={handleAddSkill}
                className="text-sm border py-1 px-2 text-[#6f6f6f] rounded font-semibold w-max mt-2"
              >
                + Add Skill
              </button>
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
};

const Experience = ({ isEditing }) => {
  const [experiences, setExperiences] = useState([]);

  const handleAddExperience = () => {
    setExperiences((prev) => {
      return [
        {
          customId: prev?.length + 1,
          isEditing: true,
          isCurrent: false,
          startDate: "",
          endDate: "",
          jobTitle: "",
          location: "",
          companyName: "",
          jobDescription: "",
          skills: [
            {
              customId: prev?.skills?.length + 1,
              skill: "JavaScript",
              value: 6,
              years: 3,
              skillsUsed: "Last Month",
            },
          ],
        },
        ...prev,
      ];
    });
  };

  // useEffect(() => {
  //   if (experiences?.length) {
  //     setData((prev) => {
  //       return {
  //         ...prev,
  //         experience: { ...prev?.experience, isFilled: true },
  //       };
  //     });
  //   } else {
  //     setData((prev) => {
  //       return {
  //         ...prev,
  //         experience: { ...prev?.experience, isFilled: false },
  //       };
  //     });
  //   }
  // }, [experiences]);

  useEffect(() => {
    getExperienceList().then((response) => {
      const { success, data } = response;
      if (success) {
        setExperiences(data);
      }
    });
  }, []);

  return (
    <div className="flex flex-col">
      <div
        className={`flex gap-3 mb-8 ${
          !experiences?.length ? "flex-col items-start" : "items-center"
        }`}
      >
        <span className="font-montserrat text-[#383838] font-bold text-sm">
          Work Experience
        </span>
        {isEditing && (
          <button
            onClick={handleAddExperience}
            className="text-sm border py-1 px-2 text-[#6f6f6f] rounded font-semibold"
          >
            + Add Experience
          </button>
        )}
      </div>
      <div className="flex flex-col gap-10 w-[90%]">
        {experiences.map((exp, index) => {
          return (
            <React.Fragment key={exp?._id ?? exp?.customId}>
              <ExperienceBlock
                key={exp?._id ?? exp?.customId}
                experienceData={exp}
                customExpId={exp?._id ?? exp?.customId}
                setExperiences={setExperiences}
              />
              {index < experiences.length - 1 && (
                <div className="bg-light-white-500 w-full h-2" />
              )}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default Experience;
