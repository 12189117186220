import { useRef, useState } from "react";
import { Controller } from "react-hook-form";
import PropTypes from "prop-types";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/high-res.css";
import es from "react-phone-input-2/lang/es.json";
import fr from "react-phone-input-2/lang/fr.json";
import ru from "react-phone-input-2/lang/ru.json";
import { colors } from "../../utils/theme";

const CustomInput = ({
  label,
  placeholder,
  type = "text",
  renderRight,
  register,
  errors,
  containerStyle,
  bgColor = "bg-white",
  activeOutlineColor = "border-gray",
  inActiveOutlineColor = "border-lightgray",
  isTextArea,
  className,
  disabled,
  resize = true,
  name,
  control,
  dropdownList,
  rules,
  filled = true,
}) => {
  const dateRef = useRef();
  const localeCodes = { es, fr, ru };
  const lngCode = "us";

  const [isFocused, setIsFocused] = useState(false);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue=""
      render={({ field }) => {
        const bgColors = disabled
          ? "bg-light-white-700"
          : `${bgColor ?? "bg-[#F7F7F7]"}`;

        return (
          <div className={`flex flex-col w-full ${containerStyle}`}>
            {label && (
              <span className="font-montserrat text-primary font-medium text-xs mb-2">
                {label}{" "}
                {rules?.required && (
                  <span className="font-montserrat text-primary font-medium text-xs text-red-500">
                    *
                  </span>
                )}
              </span>
            )}
            <div
              className={`rounded-lg ${
                type !== "phone" ? "py-2.5 px-3" : ""
              } font-montserrat font-medium text-xs flex items-center border ${bgColors} ${
                isFocused ? activeOutlineColor : inActiveOutlineColor
              } ${className}`}
            >
              {isTextArea ? (
                resize ? (
                  <textarea
                    {...field}
                    disabled={disabled}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    className="flex-1 bg-transparent outline-none"
                    placeholder={placeholder}
                    rows={5}
                    type={type ?? "text"}
                    style={{ lineHeight: "1.5" }}
                  />
                ) : (
                  <textarea
                    {...field}
                    disabled={disabled}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    className="flex-1 bg-transparent outline-none resize-none overflow-hidden"
                    placeholder={placeholder}
                    rows={1}
                    type={type ?? "text"}
                    style={{
                      lineHeight: "1.5",
                    }}
                  />
                )
              ) : type === "text" ? (
                <input
                  {...field}
                  disabled={disabled}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                  className="flex-1 bg-transparent outline-none whitespace-pre-wrap break-words overflow-hidden"
                  placeholder={placeholder}
                  type={type ?? "text"}
                  style={{ height: "auto", lineHeight: "1.5" }}
                />
              ) : type === "dropdown" ? (
                <select
                  {...field}
                  className="w-full outline-none bg-transparent"
                  name={name}
                  id={name}
                  disabled={disabled}
                >
                  {placeholder && (
                    <option
                      value=""
                      disabled
                      className={`color-[${colors.LightGrey}]`}
                    >
                      {placeholder}
                    </option>
                  )}
                  {dropdownList?.map((item) => {
                    return (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    );
                  })}
                </select>
              ) : type === "date" ? (
                <input
                  {...field}
                  disabled={disabled}
                  className="w-full"
                  type="date"
                  placeholder={placeholder}
                />
              ) : // <div
              //   onClick={() => field?.ref?.current?.click()}
              //   className="flex flex-col justify-center h-[1.123rem] w-full"
              // >
              //   <input
              //     {...field}
              //     ref={(el) => {
              //       field.ref = el; // Assign the input element to field.ref
              //     }}
              //     disabled={disabled}
              //     className="w-full hidden"
              //     type="date"
              //     placeholder={placeholder}
              //   />
              //   <span className={`${field?.value ? "" : "text-gray-400"}`}>
              //     {field?.value ? field?.value : "DD/MM/YYYY"}
              //   </span>
              // </div>
              type === "phone" ? (
                <PhoneInput
                  {...field}
                  disabled={disabled}
                  // value={field.value}
                  country={lngCode}
                  placeholder={placeholder}
                  // onChange={field.onChange}
                  localization={localeCodes[lngCode]}
                  inputProps={{
                    name,
                    required: true,
                    // autoFocus: true,
                  }}
                  isValid={(inputNumber, country) => {
                    return inputNumber.length === 10;
                  }}
                  className={`!w-full h-10`}
                  inputStyle={{
                    width: "100%",
                    boxShadow: "none",
                    height: "2.5rem",
                    background:
                      filled && !field?.value ? "white" : "transparent",
                    // background:'white',
                    borderColor: !filled ? "lightgray" : "transparent",
                  }}
                />
              ) : (
                <input
                  {...field}
                  disabled={disabled}
                  className="w-full outline-none"
                  type={type}
                  placeholder={placeholder}
                  onChange={(e) => {
                    if (type === "number") {
                      field.onChange(parseInt((e?.currentTarget?.value)));
                    } else {
                      field.onChange(e?.currentTarget?.value);
                    }
                  }}
                />
              )}
              {renderRight}
            </div>
            {errors[name]?.message && (
              <span className="whitespace-pre text-xs mt-2 text-orange-700">
                {errors[name]?.message}
              </span>
            )}
          </div>
        );
      }}
    />
  );
};

// Define prop types
CustomInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(["text", "textarea", "dropdown", "date"]),
  renderRight: PropTypes.element,
  register: PropTypes.func,
  errors: PropTypes.object,
  containerStyle: PropTypes.string,
  bgColor: PropTypes.string,
  activeOutlineColor: PropTypes.string,
  inActiveOutlineColor: PropTypes.string,
  isTextArea: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  resize: PropTypes.bool,
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  dropdownList: PropTypes.arrayOf(PropTypes.string),
  rules: PropTypes.object,
};

export default CustomInput;

// import { useState } from "react";
// import { Controller } from "react-hook-form";

// const CustomInput = ({
//   label,
//   placeholder,
//   type = "text",
//   renderRight,
//   register,
//   errors,
//   containerStyle,
//   bgColor,
//   activeOutlineColor = "border-gray",
//   inActiveOutlineColor = "border-transparent",
//   isTextArea,
//   // onFocus,
//   // onBlur
//   className,
//   disabled,
//   resize = true,
//   name,
//   control,
//   dropdownList,
//   rules
// }) => {
//   const [isFocused, setIsFocused] = useState(false);

//   return <Controller
//     name={name}
//     control={control}
//     rules={rules}
//     defaultValue=""
//     render={({ field }) => {
//       return <div className={`flex flex-col w-full ${containerStyle}`}>
//         {label && (
//           <span className="font-montserrat text-primary font-medium text-xs mb-2">
//             {label}
//           </span>
//         )}
//         <div
//           className={`rounded-lg py-2.5 px-3 font-montserrat font-medium text-xs flex items-center border ${bgColor ?? "bg-[#F7F7F7]"
//             } ${isFocused ? activeOutlineColor : inActiveOutlineColor
//             } ${className} bg-black`}
//         >
//           {isTextArea ? (
//             resize ? (
//               <textarea
//                 {...field}
//                 disabled={disabled}
//                 onFocus={() => setIsFocused(true)}
//                 onBlur={() => setIsFocused(false)}
//                 className="flex-1 bg-transparent outline-none"
//                 placeholder={placeholder}
//                 rows={5}
//                 type={type ?? "text"}
//                 style={{ lineHeight: "1.5" }}
//               />
//             ) : (
//               <textarea
//                 {...field}
//                 disabled={disabled}
//                 onFocus={() => setIsFocused(true)}
//                 onBlur={() => setIsFocused(false)}
//                 className="flex-1 bg-transparent outline-none resize-none overflow-hidden"
//                 placeholder={placeholder}
//                 rows={1}
//                 type={type ?? "text"}
//                 style={{
//                   lineHeight: "1.5",
//                 }}
//               />
//             )
//           ) : type === "text" ? (
//             <input
//               {...field}
//               disabled={disabled}
//               onFocus={() => setIsFocused(true)}
//               onBlur={() => setIsFocused(false)}
//               className="flex-1 bg-transparent outline-none whitespace-pre-wrap break-words overflow-hidden"
//               placeholder={placeholder}
//               type={type ?? "text"}
//               style={{ height: "auto", lineHeight: "1.5" }}
//             />
//           ) : type === "dropdown" ? (
//             <select
//               {...field}
//               className="w-full outline-none bg-transparent"
//               name={name}
//               id={name}
//             >
//               <option value="" disabled>
//                 {placeholder}
//               </option>
//               {dropdownList?.map((item) => {
//                 return <option value={item}>{item}</option>;
//               })}
//             </select>
//           ) : type === "date" ? (
//             <input
//               {...field}
//               className="w-full" type="date" placeholder={placeholder} />
//           ) : (
//             <input
//               {...field}
//               className="w-full" type="date" placeholder={placeholder} />

//           )}
//           {renderRight}
//         </div>
//         {errors?.message && (
//           <span className="whitespace-pre text-xs mt-2 text-orange-700">
//             {errors[name]?.message}
//           </span>
//         )}
//       </div>
//     }}
//   />

//   // return (
//   //   <div className={`flex flex-col w-full ${containerStyle}`}>
//   //     {label && (
//   //       <span className="font-montserrat text-primary font-medium text-xs mb-2">
//   //         {label}
//   //       </span>
//   //     )}
//   //     <div
//   //       className={`rounded-lg py-2.5 px-3 font-montserrat font-medium text-xs flex items-center border ${bgColor ?? "bg-[#F7F7F7]"
//   //         } ${isFocused ? activeOutlineColor : inActiveOutlineColor
//   //         } ${className} bg-black`}
//   //     >
//   //       {isTextArea ? (
//   //         resize ? (
//   //           <textarea
//   //             {...register}
//   //             disabled={disabled}
//   //             onFocus={() => setIsFocused(true)}
//   //             onBlur={() => setIsFocused(false)}
//   //             className="flex-1 bg-transparent outline-none"
//   //             placeholder={placeholder}
//   //             rows={5}
//   //             type={type ?? "text"}
//   //             style={{ lineHeight: "1.5" }}
//   //           />
//   //         ) : (
//   //           <textarea
//   //             {...register}
//   //             disabled={disabled}
//   //             onFocus={() => setIsFocused(true)}
//   //             onBlur={() => setIsFocused(false)}
//   //             className="flex-1 bg-transparent outline-none resize-none overflow-hidden"
//   //             placeholder={placeholder}
//   //             rows={1}
//   //             type={type ?? "text"}
//   //             style={{
//   //               lineHeight: "1.5",
//   //             }}
//   //           />
//   //         )
//   //       ) : type === "text" ? (
//   //         <input
//   //           {...register}
//   //           disabled={disabled}
//   //           onFocus={() => setIsFocused(true)}
//   //           onBlur={() => setIsFocused(false)}
//   //           className="flex-1 bg-transparent outline-none whitespace-pre-wrap break-words overflow-hidden"
//   //           placeholder={placeholder}
//   //           type={type ?? "text"}
//   //           style={{ height: "auto", lineHeight: "1.5" }}
//   //         />
//   //       ) : type === "dropdown" ? (
//   //         <Controller
//   //           name={name}
//   //           control={control}
//   //           defaultValue=""
//   //           render={({ field }) => (
//   //             <select
//   //               {...field}
//   //               className="w-full outline-none bg-transparent"
//   //               name={name}
//   //               id={name}
//   //             >
//   //               <option value="" disabled>
//   //                 {placeholder}
//   //               </option>
//   //               {dropdownList?.map((item) => {
//   //                 return <option value={item}>{item}</option>;
//   //               })}
//   //             </select>
//   //           )}
//   //         />
//   //       ) : type === "date" ? (
//   //         <Controller
//   //           name={name}
//   //           control={control}
//   //           defaultValue=""
//   //           render={({ field }) => (
//   //             <input className="w-full" type="date" placeholder={placeholder} />
//   //           )}
//   //         />
//   //       ) : (
//   //         <Controller
//   //           name={name}
//   //           control={control}
//   //           defaultValue=""
//   //           render={({ field }) => (
//   //             <input className="w-full" type="date" placeholder={placeholder} />
//   //           )}
//   //         />
//   //       )}
//   //       {renderRight}
//   //     </div>
//   //     {errors?.message && (
//   //       <span className="whitespace-pre text-xs mt-2 text-orange-700">
//   //         {errors?.message}
//   //       </span>
//   //     )}
//   //   </div>
//   // );
// };

// export default CustomInput;
