import { useCallback, useEffect, useState } from "react";
import ChevronLeftIcon from "../../assets/svg/ChevronLeftIcon";
import ChevronRightIcon from "../../assets/svg/ChevronRightIcon";
import IndustryIcon from "../../assets/svg/IndustryIcon";
import SkillIcon from "../../assets/svg/SkillIcon";
import StackIcon from "../../assets/svg/StackIcon";
import DoubleTick from "../../assets/svg/DoubleTick";
import { useForm } from "react-hook-form";
import Step2 from "./components/Step2";
import Step3 from "./components/Step3";
import { useLocation, useNavigate } from "react-router-dom";
import Step1 from "./components/Step1";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyList } from "../../endpoints/company.service";
import { setCompanies } from "../../store/actions/company.action";
import { addJob, updateJob } from "../../endpoints/job.service";
import { JobStatus, JobType } from "../../utils/constants";

const CreateJobPage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [steps, setSteps] = useState([
    {
      label: "Company",
      icon: <IndustryIcon className={"w-5 h-5"} fill={"#6f6f6f"} />,
      isCompleted: false,
    },
    {
      label: "Basic Details",
      icon: <StackIcon className={"w-5 h-5"} />,
      isCompleted: false,
    },
    {
      label: "Skills",
      icon: <SkillIcon className={"w-5 h-5"} fill={"#6f6f6f"} />,
      isCompleted: false,
    },
  ]);
  const { companies } = useSelector((state) => state?.company);
  const [createNewCompany, setCreateNewCompany] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const {
    setValue,
    watch,
    control,
    trigger,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      companiesLoading: companies?.length > 0 ? false : true,
      industryType: state ? state?.industryType : undefined,
      jobDescription: state ? state?.description : undefined,
      jobLocation: state ? state?.location : undefined,
      jobTitle: state ? state?.title : undefined,
      jobType: state
        ? JobType?.find((x) => state?.jobType === x.value)?.label
        : undefined,
      salaryRange: state
        ? `${state?.salary?.min} - ${state?.salary?.max}`
        : undefined,
      isSalaryHidden: state ? state?.isSalaryHidden : false,
      selectedCompany: state
        ? companies?.find((x) => x._id === state?.companyId)
        : undefined,
      createdDraftJob: state ? state : undefined,
    },
    mode: "onChange",
  });

  useEffect(() => {
    getCompanyList()
      .then((response) => {
        const { data, success } = response;
        if (success) {
          dispatch(setCompanies(data));
        }
      })
      .finally(() => setValue("companiesLoading", false));
  }, [createNewCompany]);

  const fields = watch();

  const handleNext = () => {
    if (createNewCompany) {
      setCreateNewCompany(false);
      return;
    }
    if (activeStep === 2) {
      trigger();
      if (isValid) {
        createJob(fields);
      }
      return;
    }
    if (activeStep === 3) {
      finishJob();
      return;
    }
    setSteps((prev) => {
      return prev?.map((item, itemIndex) => {
        if (itemIndex + 1 === activeStep) {
          return { ...item, isCompleted: true };
        } else {
          return item;
        }
      });
    });
    if (activeStep !== 3) {
      setActiveStep((prev) => prev + 1);
    } else if (activeStep === 3) {
      enqueueSnackbar("Job Created Successfully!", { variant: "success" });
    }
  };

  const createJob = useCallback(async (data) => {
    try {
      let response;
      const job = JobType.find((x) => x.label === data?.jobType);
      if (data?.createdDraftJob) {
        response = await updateJob({
          jobId: data?.createdDraftJob?._id,
          title: data?.jobTitle,
          description: data?.jobDescription,
          location: data?.jobLocation,
          salary: {
            min: data?.salaryRange?.split("-")?.[0]?.trim(),
            max: data?.salaryRange?.split("-")?.[1]?.trim(),
          },
          industryType: data?.industryType,
          jobType: job?.value,
          companyId: data?.selectedCompany?._id,
          status: JobStatus.DRAFT,
          isSalaryHidden: data?.isSalaryHidden,
        });
      } else {
        response = await addJob({
          title: data?.jobTitle,
          description: data?.jobDescription,
          location: data?.jobLocation,
          salary: {
            min: data?.salaryRange?.split("-")?.[0]?.trim(),
            max: data?.salaryRange?.split("-")?.[1]?.trim(),
          },
          industryType: data?.industryType,
          jobType: job?.value,
          companyId: data?.selectedCompany?._id,
          status: JobStatus.DRAFT,
          isSalaryHidden: data?.isSalaryHidden,
        });
      }

      if (response?.success) {
        setValue("createdDraftJob", response?.data);
        setSteps((prev) => {
          return prev?.map((item, itemIndex) => {
            if (itemIndex + 1 === activeStep) {
              return { ...item, isCompleted: true };
            } else {
              return item;
            }
          });
        });
        setActiveStep((prev) => prev + 1);
      }
    } catch (error) {
      enqueueSnackbar(error, { variant: "error" });
    }
  }, []);

  const finishJob = useCallback(async () => {
    try {
      const data = await updateJob({
        jobId: fields?.createdDraftJob?._id,
        status: JobStatus.LIVE,
      });
      console.log("data", data);

      if (data?.success) {
        console.log("here");

        navigate(-1);
      }
    } catch (error) {
      enqueueSnackbar(error, { variant: "error" });
    }
  }, [fields]);

  const handlePrevious = () => {
    setActiveStep((prev) => prev - 1);
  };

  return (
    <div className="flex flex-col rounded-lg bg-white w-full flex-grow p-4">
      <button onClick={() => navigate(-1)} className="flex items-center">
        <ChevronLeftIcon className={"w-4 h-4"} fill="#4d4d4d" />
        <label className="text-xs font-medium text-primary">
          Back to listing
        </label>
      </button>
      <form className="flex flex-col items-center w-full h-full">
        <span className="font-medium text-sm">Add Company</span>
        {/* Progress indicator section */}
        <div className="flex justify-between items-center mt-4 border-b pb-5 w-full">
          <button
            type="button"
            onClick={handlePrevious}
            className={`bg-[#ececec] rounded-md text-xs px-4 py-2 self-end ${
              activeStep > 1 ? "" : "opacity-0"
            }`}
          >
            Previous
          </button>
          {/* Indicators */}
          <div className="flex gap-4 items-center">
            {steps?.map((step, stepIndex) => {
              const isActive = stepIndex + 1 === activeStep;
              return (
                <>
                  {stepIndex !== 0 ? (
                    <div className="w-14 h-px border-dashed border-b border-light-black" />
                  ) : null}
                  <div
                    className={`flex gap-6 ${
                      isActive ? "bg-light-white-200" : ""
                    } ${
                      step?.isCompleted || isActive ? "" : "opacity-40"
                    } rounded-2xl px-4 py-2 items-center`}
                  >
                    <div className="flex items-center gap-2">
                      {step?.icon}
                      <label className="font-medium text-xs text-primary">
                        {step?.label}
                      </label>
                    </div>
                    {step?.isCompleted ? (
                      <DoubleTick className={"w-3 h-3"} fill="#2e2e2e" />
                    ) : (
                      <ChevronRightIcon className={"w-3 h-3"} fill="#2e2e2e" />
                    )}
                  </div>
                </>
              );
            })}
          </div>
          <button
            type="button"
            onClick={handleNext}
            className="bg-[#ececec] rounded-md text-xs px-4 py-2 self-end"
          >
            {createNewCompany && activeStep === 1
              ? "Create"
              : activeStep === 2 ||
                (activeStep === 1 && !!fields?.selectedCompany)
              ? "Next"
              : activeStep === 1
              ? "Skip"
              : "Finish"}
          </button>
        </div>
        {/* Body Container */}
        <div className="flex flex-col py-10 max-h-fit overflow-y-scroll no-scrollbar w-full">
          {activeStep === 1 ? (
            <Step1
              setCreateNewCompany={setCreateNewCompany}
              createNewCompany={createNewCompany}
              loading={fields?.companiesLoading}
              setValue={setValue}
              fields={fields}
            />
          ) : activeStep === 2 ? (
            <Step2 control={control} errors={errors} />
          ) : (
            <Step3 createdDraftJob={fields?.createdDraftJob} />
          )}
        </div>
      </form>
    </div>
  );
};

export default CreateJobPage;
