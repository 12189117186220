import { useEffect, useState } from "react";

const CloseIcon = ({ className, fill }) => {
  const [_class, setClass] = useState(className);

  useEffect(() => {
    let classStr = className || "";
    if (!classStr.includes("w-")) {
      classStr += " w-5";
    }
    if (!classStr.includes("h-")) {
      classStr += " h-5";
    }

    setClass(classStr);
  }, [className]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 23 24"
      stroke-width="0"
      stroke={fill}
      class={_class}
    >
      <path
        d="M4.9292 5.31445L18.0859 18.4711M4.9292 18.4711L18.0859 5.31445"
        stroke="#2E2E2E"
        stroke-width="1.87952"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default CloseIcon;
