import { forwardRef, useRef, useState } from "react";
import profile from "../../assets/profilePic3.png";
import Details from "./components/Details";
import About from "./components/About";
import Experience from "./components/Experience";
import Skills from "./components/Skills";
import Education from "./components/Education";
import Contacts from "./components/Contacts";
import { useNavigate } from "react-router-dom";
import { updateCandidate } from "../../endpoints/candidate.service";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { updateDisableStatus } from "../../endpoints/user.service";
import { setUserData } from "../../store/actions/auth.action";

const tabs = [
  {
    label: "Details",
    value: "details",
  },
  {
    label: "About",
    value: "about",
  },
  {
    label: "Experience",
    value: "experience",
  },
  {
    label: "Skills",
    value: "skills",
  },
  {
    label: "Education & Certifications",
    value: "educationAndCertificate",
  },
  {
    label: "Contact Details",
    value: "contactDetails",
  },
];

const RenderBody = forwardRef((props, ref) => {
  const {
    data,
    setData,
    activeTab,
    isEditing,
    editingTab,
    setEditingTab,
    handleNext,
  } = props;

  return (
    <>
      {activeTab === "details" && (
        <Details
          ref={ref} // Pass the ref to Details
          data={data}
          setData={setData}
          handleNext={handleNext}
        />
      )}
      {activeTab === "about" && (
        <About
          ref={ref} // Pass the ref to Details
          data={data}
          setData={setData}
          isEditing={data?.about?.isEditing}
          handleNext={handleNext}
        />
      )}
      {activeTab === "experience" && (
        <Experience
          ref={ref} // Pass the ref to Details
          data={data}
          setData={setData}
          handleNext={handleNext}
          isEditing={data?.experience?.isEditing}
        />
      )}
      {activeTab === "skills" && <Skills />}
      {activeTab === "educationAndCertificate" && (
        <Education
          ref={ref} // Pass the ref to Details
          data={data}
          setData={setData}
          handleNext={handleNext}
        />
      )}
      {activeTab === "contactDetails" && (
        <Contacts
          ref={ref} // Pass the ref to Details
          data={data}
          setData={setData}
          handleNext={handleNext}
        />
      )}
    </>
  );
});

const CreateProfile = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const imageContainerRef = useRef(null);
  const formRef = useRef(null);
  const { user } = useSelector((state) => state.auth);

  const [activeTab, setActiveTab] = useState("details");
  const [isEditing, setIsEditing] = useState(false);
  const [editingTab, setEditingTab] = useState(null);
  const [data, setData] = useState({
    details: { isCompleted: false, isEditing: true, isFilled: false },
    about: { isCompleted: false, isEditing: true, isFilled: false },
    experience: { isCompleted: false, isEditing: true, isFilled: false },
    skills: { isCompleted: false, isEditing: true, isFilled: false },
    educationAndCertificate: {
      isCompleted: false,
      isEditing: true,
      isFilled: false,
    },
    contactDetails: { isCompleted: false, isEditing: true, isFilled: false },
  });

  const currentIndex = tabs.findIndex((tab) => tab.value === activeTab);
  const isFilled = data[tabs[currentIndex]?.value]?.isFilled;

  const nextStep = () => {
    const nextIndex = (currentIndex + 1) % tabs.length;
    setActiveTab(tabs[nextIndex].value);
  };

  const handleNextClick = (data) => {
    if (formRef.current) {
      formRef.current();
    }
  };

  const handleNext = (data) => {
    if (activeTab === "details") {
      const payload = data;
      updateCandidate(payload).then((response) => {
        const { success } = response;
        if (success) {
          setData((prev) => {
            return {
              ...prev,
              details: {
                ...prev?.details,
                isCompleted: true,
                isEditing: false,
              },
            };
          });
          nextStep();
          enqueueSnackbar("Details saved successfully.", {
            variant: "success",
          });
        }
      });
      updateDisableStatus().then(({ success }) => {
        if (success) {
          dispatch(setUserData({ enableDefaultProfileOption: false }));
        }
      });
    } else if (activeTab === "about") {
      updateCandidate(data).then((response) => {
        const { success } = response;
        if (success) {
          setData((prev) => {
            return {
              ...prev,
              about: { ...prev?.about, isCompleted: true, isEditing: false },
            };
          });
          nextStep();
          enqueueSnackbar("About saved successfully.", {
            variant: "success",
          });
        }
      });
    } else if (activeTab === "experience") {
      setData((prev) => {
        return {
          ...prev,
          experience: {
            ...prev?.experience,
            isCompleted: true,
            isEditing: false,
          },
        };
      });
      nextStep();
    } else if (activeTab === "education") {
      setData((prev) => {
        return {
          ...prev,
          educationAndCertificate: {
            ...prev?.educationAndCertificate,
            isCompleted: true,
            isEditing: false,
          },
        };
      });
      nextStep();
    }
  };

  return (
    <div className="flex flex-col rounded-lg bg-white p-4 flex-grow w-full">
      {/* Top container */}
      <div className="flex items-center bg-[#FAFAFA] py-3.5 pl-3.5 pr-[18px] rounded-xl border border-[#F7F7F7] mb-1 relative">
        {/* Image container */}
        <div ref={imageContainerRef} className="absolute top-0">
          <img src={profile} className="rounded-full h-40 w-40" />
        </div>
        <div className="flex items-center justify-between flex-1 gap-1 pl-44">
          <span className="font-montserrat text-primary font-semibold text-xl w-max">
            {user?.firstName} {user?.lastName}
          </span>

          <span className="font-montserrat text-light-black font-medium text-[11px] max-[900px]:w-min italic">
            CA-FQ1478
          </span>
        </div>
      </div>
      <div className="flex items-center pl-48 py-7">
        {tabs?.map((tab, tabIndex) => {
          const isSelected = tab?.value === activeTab;
          const isCompleted = data[tab?.value]?.isCompleted;
          return (
            <button
              key={tab?.value}
              onClick={() => setActiveTab(tab?.value)}
              className="flex items-center"
            >
              {tabIndex !== 0 ? (
                <div className="h-px w-10 border-t border border-dashed mx-3" />
              ) : null}
              <span
                className={`font-medium text-xs ${
                  isCompleted
                    ? "text-green-700"
                    : isSelected
                    ? "text-secondary"
                    : "text-light-grey"
                }`}
              >
                {tab?.label}
              </span>
            </button>
          );
        })}
      </div>
      <button
        onClick={() => {
          if (activeTab === "contactDetails") {
            navigate("/dashboard");
          } else {
            if (isFilled) {
              handleNextClick();
            } else {
              nextStep();
            }
          }
        }}
        className="font-medium rounded-md border w-max self-end px-4 py-1 text-sm mr-3 text-light-black hover:bg-grey-100"
      >
        {/* Skip */}
        {isFilled ? "Next" : "Skip"}
      </button>

      {/* Body container */}
      <div className="flex-1 overflow-y-auto no-scrollbar pl-48 mt-5">
        <RenderBody
          ref={formRef}
          data={data}
          setData={setData}
          activeTab={activeTab}
          isEditing={isEditing}
          editingTab={editingTab}
          setEditingTab={setEditingTab}
          handleNext={handleNext}
        />
      </div>
    </div>
  );
};

export default CreateProfile;
