import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CustomInput from "../../../components/CustomInput/CustomInput";
import {
  getCandidateDetails,
  updateCandidate,
} from "../../../endpoints/candidate.service";
import { useSnackbar } from "notistack";
import { extractDialCode } from "../../../utils/helperFunction";

const Contacts = ({ isEditing }) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors, isValid },
  } = useForm({});

  const handleSave = async (formData) => {
    const payload = {
      ...formData,
      countryCode: extractDialCode("+" + formData?.phoneNo)?.dialCode,
      phoneNo: extractDialCode("+" + formData?.phoneNo)?.localNumber,
    };

    const { success } = await updateCandidate(payload);
    if (success) {
      enqueueSnackbar("Contact saved successfully.", {
        variant: "success",
      });
    }
  };

  const fetchCandidate = async () => {
    const { success, data } = await getCandidateDetails();
    if (success) {
      setValue("address", data?.address);
      setValue("email", data?.email);
      setValue("phoneNo", (data?.countryCode + data?.phoneNo).toString());
    }
  };

  useEffect(() => {
    fetchCandidate();
  }, []);

  return (
    <div className="flex flex-col">
      <div className={`flex flex-col items-start gap-3 mb-8`}>
        <span className="font-montserrat text-[#383838] font-bold text-sm">
          Contact Details
        </span>
      </div>

      <div className="flex flex-col w-2/4">
        <p
          className={`text-[#6f6f6f] text-xs font-medium cursor-pointer flex justify-end`}
          onClick={handleSubmit(handleSave)}
        >
          Save
        </p>
        <form className="flex flex-col gap-4">
          <CustomInput
            label={"Address"}
            name="address"
            placeholder={"123 West Street, Utopia, UK, 230093"}
            rules={{ required: "This field is required." }}
            control={control}
            errors={errors}
          />
          <CustomInput
            type="phone"
            label={"Contact Number"}
            name="phoneNo"
            placeholder={"+1  999-999-9999"}
            rules={{ required: "This field is required." }}
            control={control}
            errors={errors}
          />
          <CustomInput
            label={"Email Address"}
            name="email"
            placeholder={"abc@gmail.com"}
            rules={{ required: "This field is required." }}
            control={control}
            errors={errors}
          />
        </form>
      </div>
    </div>
  );
};

export default Contacts;
