import api from "../api";
import axios from "../utils/axios.instance";
import handleError from "./handle-error";

export const getJobs = async (payload) => {
  try {
    const { data } = await axios.post(api.getJobList, payload);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const addJob = async (payload) => {
  try {
    const { data } = await axios.post(api.addJob, payload);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const updateJob = async (payload) => {
  try {
    const { data } = await axios.patch(api.updateJob, payload);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const deleteJob = async (jobId) => {
  try {
    const { data } = await axios.delete(api.deleteJob + jobId);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};
