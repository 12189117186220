import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import QuillEditor from "../../../components/CreateQuestion/Question/QuillEditor";
import { Controller, useForm } from "react-hook-form";
import { getCandidateDetails } from "../../../endpoints/candidate.service";

const About = forwardRef(({ isEditing, setData, handleNext }, ref) => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      about: "",
    },
  });
  const [aboutText, setAboutText] = useState("");

  // const handleTextChange = (value) => {
  //   setAboutText(value);
  //   setData((prev) => {
  //     return {
  //       ...prev,
  //       about: { ...prev?.about, isFilled: true },
  //     };
  //   });
  // };

  useImperativeHandle(ref, () => handleSubmit(handleNext));

  const fetchCandidate = async () => {
    const { success, data } = await getCandidateDetails();
    if (success) {
      if (data?.about) {
        setValue("about", data?.about);
        setAboutText(data?.about);
        setData((prev) => {
          return {
            ...prev,
            about: { ...prev?.about, isEditing: false },
          };
        });
      } else {
        setData((prev) => {
          return {
            ...prev,
            about: { ...prev?.about, isEditing: true },
          };
        });
      }
    }
  };

  useEffect(() => {
    fetchCandidate();
  }, []);

  useEffect(() => {
    if (isDirty) {
      setData((prev) => {
        return {
          ...prev,
          about: { ...prev?.about, isFilled: true },
        };
      });
    } else {
      setData((prev) => {
        return {
          ...prev,
          about: { ...prev?.about, isFilled: false },
        };
      });
    }
  }, [isDirty]);

  return (
    <div>
      {!isEditing ? (
        <div className="h-full flex flex-1 flex-col overflow-y-scroll no-scrollbar w-4/5">
          <div className="flex flex-col">
            <span className="font-montserrat text-[#383838] font-bold text-sm mb-8">
              About
            </span>
            <div
              className="font-montserrat text-primary font-medium text-xs leading-5 mb-3"
              dangerouslySetInnerHTML={{ __html: aboutText }}
            />
          </div>
        </div>
      ) : (
        <div className="flex flex-col">
          <span className="font-montserrat text-[#383838] font-bold text-sm mb-8">
            About
          </span>
          <div className="mr-4">
            <Controller
              name={"about"}
              control={control}
              rules={{
                required: "This field is required.",
              }}
              render={({ field }) => {
                return (
                  <div className="flex flex-col gap-1">
                    <QuillEditor {...field} />
                    {errors["about"]?.message && (
                      <span className="whitespace-pre text-xs mt-2 text-orange-700">
                        {errors["about"]?.message}
                      </span>
                    )}
                  </div>
                );
              }}
            />
            {/* <QuillEditor value={aboutText} onChange={handleTextChange} /> */}
          </div>
        </div>
      )}
    </div>
  );
});

export default About;
