import { useEffect, useState } from "react";

const LocationIconOutline = ({ className, fill }) => {
    const [_class, setClass] = useState(className)

    useEffect(() => {
        let classStr = className || '';
        if (!classStr.includes('w-')) {
            classStr += ' w-5';
        }
        if (!classStr.includes('h-')) {
            classStr += ' h-5';
        }

        setClass(classStr);
    }, [className])

    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" stroke-width="0" stroke={fill} class={_class}>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.16499 13.979C8.93173 13.2833 9.64135 12.5271 10.287 11.7177C11.647 10.009 12.4743 8.32434 12.5303 6.82634C12.5525 6.21754 12.4517 5.61051 12.234 5.04153C12.0163 4.47254 11.6862 3.95327 11.2633 3.51473C10.8405 3.07619 10.3335 2.72739 9.77285 2.48914C9.21216 2.2509 8.6092 2.12811 7.99999 2.12811C7.39079 2.12811 6.78783 2.2509 6.22714 2.48914C5.66645 2.72739 5.15953 3.07619 4.73666 3.51473C4.3138 3.95327 3.98366 4.47254 3.76597 5.04153C3.54827 5.61051 3.4475 6.21754 3.46966 6.82634C3.52633 8.32434 4.35433 10.009 5.71366 11.7177C6.3593 12.5271 7.06892 13.2833 7.83566 13.979C7.90944 14.0457 7.96433 14.0941 8.00033 14.1243L8.16499 13.979ZM7.50833 14.7557C7.50833 14.7557 2.66699 10.6783 2.66699 6.66634C2.66699 5.25185 3.2289 3.8953 4.22909 2.89511C5.22928 1.89491 6.58584 1.33301 8.00033 1.33301C9.41481 1.33301 10.7714 1.89491 11.7716 2.89511C12.7718 3.8953 13.3337 5.25185 13.3337 6.66634C13.3337 10.6783 8.49233 14.7557 8.49233 14.7557C8.22299 15.0037 7.77966 15.001 7.50833 14.7557ZM8.00033 8.53301C8.4954 8.53301 8.97019 8.33634 9.32026 7.98627C9.67033 7.63621 9.86699 7.16141 9.86699 6.66634C9.86699 6.17127 9.67033 5.69648 9.32026 5.34641C8.97019 4.99634 8.4954 4.79967 8.00033 4.79967C7.50525 4.79967 7.03046 4.99634 6.68039 5.34641C6.33032 5.69648 6.13366 6.17127 6.13366 6.66634C6.13366 7.16141 6.33032 7.63621 6.68039 7.98627C7.03046 8.33634 7.50525 8.53301 8.00033 8.53301ZM8.00033 9.33301C7.29308 9.33301 6.6148 9.05206 6.11471 8.55196C5.61461 8.05186 5.33366 7.37359 5.33366 6.66634C5.33366 5.9591 5.61461 5.28082 6.11471 4.78072C6.6148 4.28063 7.29308 3.99967 8.00033 3.99967C8.70757 3.99967 9.38585 4.28063 9.88594 4.78072C10.386 5.28082 10.667 5.9591 10.667 6.66634C10.667 7.37359 10.386 8.05186 9.88594 8.55196C9.38585 9.05206 8.70757 9.33301 8.00033 9.33301Z" fill="#6F6F6F" />
        </svg>
    )
};

export default LocationIconOutline;