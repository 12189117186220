import React from "react";
import { staffs } from "../dummyData";
import { colors } from "../../../../utils/theme";
import LikeIconLeft from "../../../../assets/svg/LikeIconLeft";
import ReferIcon from "../../../../assets/svg/ReferIcon";
import TrackingIcon from "../../../../assets/svg/TrackingIcon";
import ShareIconOutline from "../../../../assets/svg/ShareIconOutline";
import MyContactsIcon from "../../../../assets/svg/MyContactsIcon";
import Message from "../../../../assets/svg/Message";
import Phone from "../../../../assets/svg/Phone";
import Email from "../../../../assets/svg/Email";

const StaffItem = (staff) => {
  return (
    <div className={`py-4 px-3 rounded-sm bg-[${colors.BackgroundGray}]`}>
      <div className="flex gap-4 items-center">
        <img src={staff?.image} className="rounded-full h-[72px] w-[72px]" />
        <div className="flex flex-1 flex-col gap-2">
          <span className="font-montserrat text-primary font-semibold text-2xl border-b border-primary w-max pb-1">
            {staff?.name}
          </span>
          <span className="font-montserrat text-primary font-medium text-xs max-[900px]:w-min">
            {staff?.position}
          </span>
        </div>
        <div className="flex flex-1 flex-col gap-3">
          <div className="flex gap-3">
            <Email />
            <span className="font-montserrat text-primary font-normal text-xs">
              {staff?.email}
            </span>
          </div>
          <div className="flex gap-3">
            <Phone />
            <span className="font-montserrat text-primary font-normal text-xs">
              {staff?.phone}
            </span>
          </div>
        </div>
      </div>
      <div className="flex mt-4 pt-2 border-t border-dashed gap-4 items-center">
        <div className="flex flex-1 ml-1 items-center gap-2">
          <div className="w-[8px] h-[8px] bg-[#23BD33] rounded-full animate-pulse" />
          <span className="font-montserrat font-medium text-[10px] text-light-black">
            {staff?.status}
          </span>
          <div className="flex ml-8 gap-2">
            <Message />
            <span className="font-montserrat text-primary font-normal text-xs">
              Message
            </span>
          </div>
        </div>
        <div className="flex flex-1 ml-[80px] gap-10">
          <div className="group flex flex-col justify-between items-center cursor-pointer h-9">
            <LikeIconLeft className="w-7 h-7 mt-0.5" fill={"#2E2E2E"} />
            <span className="font-medium uppercase text-[8px] text-light-black">
              Likes
            </span>
          </div>
          <div className="group flex flex-col justify-between items-center cursor-pointer h-9">
            <TrackingIcon className={"w-6 h-6"} fill={"#2E2E2E"} />
            <span className="font-medium uppercase text-[8px] text-light-black">
              Track
            </span>
          </div>
          <div className="group flex flex-col justify-between items-center cursor-pointer h-9">
            <ShareIconOutline className="w-6 h-6" />
            <span className="font-medium uppercase text-[8px] text-light-black">
              Share
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const Staff = () => {
  return (
    <div className="flex flex-col gap-6">
      {staffs.map((staff) => (
        <StaffItem key={staff._id} {...staff} />
      ))}
    </div>
  );
};

export default Staff;
