import axios from "../utils/axios.instance";
import api from "../api";
import handleError from "./handle-error";

export const updateUser = async (params) => {
  try {
    const { data } = await axios.put(api.update, params);
    return data;
  } catch (err) {
    return handleError(err);
  }
};

export const getUser = async (id) => {
  try {
    const { data } = await axios.get(api.getUser(id));
    if (data.success) {
      return data.user;
    }
    throw new Error(data);
  } catch (err) {
    return handleError(err);
  }
};

export const updateDisableStatus = async () => {
  try {
    const { data } = await axios.patch(api.updateDisableStatus);
    if (data.success) {
      return data;
    }
    throw new Error(data);
  } catch (err) {
    return handleError(err);
  }
};

export const updateProfileImage = async (mediaId) => {
  try {
    const { data } = await axios.post(api.updateProfileImage, {
      mediaId: mediaId,
    });
    if (data && data.success) return data.profileImage;

    throw Error("Failed to save profile image.");
  } catch (err) {
    return handleError(err);
  }
};
