import React, { useState, useRef, useEffect } from "react";
import './index.css'
const VerificationCodeInput = ({ length, label, loading, onChange }) => {
    const [code, setCode] = useState([...Array(length)].map(() => ""));
    const inputs = useRef([]);
    const processInput = (e, slot) => {
        const num = e.target.value;
        // if (/[^0-9]/.test(num)) return;
        const newCode = [...code];
        newCode[slot] = num;
        setCode(newCode);
        if (slot !== length - 1) {
            inputs.current[slot + 1].focus();
        }
    };

    const onKeyUp = (e, slot) => {
        if (e.keyCode === 8 && !code[slot] && slot !== 0) {
            inputs.current[slot - 1].focus();
            const newCode = [...code];
            newCode[slot - 1] = "";
            setCode(newCode);
        }
    };

    const handlePaste = (copiedText) => {
        const newCode = [...code];
        for (let index = 0; index < copiedText?.length; index++) {
            if (index < length) {
                newCode[index] = copiedText.substr(index, 1);
                if (index !== length - 1) {
                    inputs.current[index + 1].focus();
                }
            }
        }
        setCode(newCode)
    }

    useEffect(() => {
        onChange(code.join(""));
    }, [code])

    console.log('LOG CODE', code)

    return (
        <div className="flex w-full items-center gap-4 w-max">
            {code.map((num, idx) => {
                return (
                    <input
                        className="border w-14 h-14 text-center rounded-xl focus:outline-none font-semibold text-xl font-montserrat"
                        key={idx}
                        type="text"
                        // inputMode="numeric"
                        onPaste={(e) => handlePaste(e?.clipboardData?.getData('text/plain'))}
                        maxLength={1}
                        value={num}
                        autoFocus={!code[0].length && idx === 0}
                        readOnly={loading}
                        onChange={e => processInput(e, idx)}
                        onKeyUp={e => onKeyUp(e, idx)}
                        ref={ref => inputs.current.push(ref)}
                    />
                );
            })}
        </div>
    );
};

export default VerificationCodeInput;
