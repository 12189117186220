import EmailOpenIcon from "../../assets/svg/EmailOpenIcon";
import EmailSuccess from "../../assets/svg/EmailSuccess";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import VerificationCodeInput from "../../components/OtpInputBox/VerificationCodeInput";
import { resendOtpVerifyEmail, verifyEmail } from "../../endpoints/auth.service";

const EmailVerification = () => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { state } = useLocation();
    const { authenticated, logged_in, user, token } = useSelector((state) => state.auth);
    const [showInputBox, setShowInputBox] = useState(false);
    const [verifiedSuccess, setVerifiedSuccess] = useState(false);
    const [code, setCode] = useState()

    const handleSubmitButton = () => {
        if (verifiedSuccess) {
            navigate('/login')
        } else if (!showInputBox) {
            resendOtpVerifyEmail(state?.email).then((res) => {
                setShowInputBox(true)
            })
        } else if (showInputBox) {
            verifyEmail({
                email: state?.email,
                otpCode: code
            }).then((res) => {
                setVerifiedSuccess(true)
            })
        }
    }

    useEffect(() => {
        if (state?.isSignup) {
            setShowInputBox(true)
        }
    }, [state])

    useEffect(() => {

    }, [showInputBox])

    return <div className="flex flex-col items-center my-20 border rounded-xl px-10 w-max py-10 mx-auto bg-white">
        <span className="font-didact font-semibold text-3xl">{verifiedSuccess ? 'Verified!!' : 'Email Verification'}</span>
        <span className="font-montserrat text-light-text font-medium mt-3">{verifiedSuccess ? 'Yahoo! Account activated.' : 'Thanks for registering with us.'}</span>
        {
            (showInputBox && !verifiedSuccess) ?
                null
                :
                verifiedSuccess ?
                    <EmailSuccess className={'w-14 h-14 my-4'} />
                    :
                    <EmailOpenIcon className={'w-14 h-14 my-4'} />
        }

        {
            (showInputBox && !verifiedSuccess) ?
                <span className="font-montserrat text-light-black font-medium text-sm w-96 text-center">A verification code has been sent to your email address
                    <span className="font-semibold"> {state?.email}</span>, please check your inbox and
                    insert the code to verify your email.</span>
                :
                verifiedSuccess ?
                    <span className="font-montserrat text-light-black font-medium text-sm w-96 text-center">Thank you, your email has been verified. Your account is now active. Please use the link below to login to your account.</span>
                    :
                    <span className="font-montserrat text-light-black font-medium text-sm w-96 text-center">You’re almost ready to get started. Please click on the button below to verify your email address.</span>
        }

        {
            (showInputBox && !verifiedSuccess) ?
                <div className="mt-3">
                    <VerificationCodeInput
                        loading={false}
                        onChange={codeNum => {
                            setCode(codeNum)
                        }}
                        length={6} />
                </div>
                :
                null
        }
        <button
            disabled={(code?.length !== 6) && showInputBox}
            onClick={handleSubmitButton}
            // onClick={() => {
            //     if (verifiedSuccess) {
            //         navigate('/login')
            //     } else if (!showInputBox) {
            //         setShowInputBox(true)
            //     } else if (!verifiedSuccess) {
            //         registerEmailVerify({
            //             email: state?.email,
            //             // email: 'jacktrainer@yopmail.com',
            //             verify_code: code
            //         }).then(() => {
            //             setVerifiedSuccess(true)
            //             setShowInputBox(false)
            //         }).catch((err) => {
            //             // console.log('Error',err);
            //             enqueueSnackbar(err, { variant: "error" });
            //         })
            //     }
            // }}
            className={`font-montserrat font-medium text-sm text-white bg-secondary hover:bg-secondary rounded-full py-3 w-[28rem] mt-3 ${((code?.length !== 6) && showInputBox) ? 'opacity-50' : 'opacity-100'}`}
        >
            {(showInputBox && !verifiedSuccess) ? 'Verify' : verifiedSuccess ? 'Login to your account' : 'Verify your email address'}
        </button>
        {
            !verifiedSuccess ?
                <span className="font-montserrat mt-2.5 text-light-text font-medium text-xs">Didn't receive {showInputBox ? 'the code' : 'verification email'}? <span className="text-secondary">Resend {showInputBox ? 'code' : ''}</span></span>
                :
                null
        }
    </div>
}

export default EmailVerification