import { Controller, useForm } from "react-hook-form";
import CustomInput from "../../../components/CustomInput/CustomInput";
import { useEffect, useRef, useImperativeHandle, forwardRef } from "react";
import { candidateStatus } from "../../../utils/constants";
import { getCandidateDetails } from "../../../endpoints/candidate.service";

const availabilityStatus = Object.entries(candidateStatus).map(
  ([key, value]) => ({
    title: value,
    id: key,
  })
);

const Details = forwardRef(({ data, setData, handleNext }, ref) => {
  const filePickerRef = useRef();
  const {
    setValue,
    handleSubmit,
    control,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: {
      jobTitle: "",
      status: "",
      location: "",
    },
  });

  useEffect(() => {
    if (isDirty) {
      setData((prev) => {
        return { ...prev, details: { ...prev?.details, isFilled: true } };
      });
    } else {
      setData((prev) => {
        return { ...prev, details: { ...prev?.details, isFilled: false } };
      });
    }
  }, [isDirty]);

  useImperativeHandle(ref, () => handleSubmit(handleNext));

  const fetchCandidate = async () => {
    const { success, data } = await getCandidateDetails();
    if (success && data?.jobTitle) {
      setValue("jobTitle", data?.jobTitle);
      setValue("status", data?.status);
      setValue("location", data?.location);
      setData((prev) => {
        return { ...prev, details: { ...prev?.details, isFilled: true } };
      });
    }
  };

  useEffect(() => {
    fetchCandidate();
  }, []);

  return (
    <div className="flex flex-col w-2/3">
      <form className="flex flex-col w-full">
        <div className="flex flex-col">
          <button
            type="button"
            onClick={() => filePickerRef?.current?.click()}
            className="flex items-center border rounded-lg border overflow-hidden pr-2"
          >
            <span className="text-sm text-[#6f6f6f] font-medium py-2.5 px-3 bg-[#E9EAF0]">
              PNG/JPG
            </span>
            <span className="text-xs font-medium px-3 text-ngray flex-1 text-left">
              Upload your profile picture
            </span>
          </button>
          <input ref={filePickerRef} type="file" className="hidden" />
        </div>
        <div className="h-6" />
        <CustomInput
          label={"Job Title"}
          name="jobTitle"
          placeholder={"Ex: Project Manager"}
          rules={{
            required: "This field is required.",
          }}
          control={control}
          errors={errors}
        />
        <div className="h-6" />
        <Controller
          name={"status"}
          control={control}
          rules={{
            required: "This field is required.",
          }}
          defaultValue=""
          render={({ field }) => {
            return (
              <div className={`flex flex-col w-full`}>
                <span className="font-montserrat text-primary font-medium text-xs mb-2">
                  Work Status
                  <span className="font-montserrat text-primary font-medium text-xs text-red-500">
                    *
                  </span>
                </span>
                <div
                  className={`rounded-lg py-2.5 px-3 font-montserrat font-medium text-xs flex items-center border`}
                >
                  <select
                    {...field}
                    className="w-full outline-none bg-transparent focus:outline-none focus:border-blue-500 text-primary"
                    name={"status"}
                    id={"status"}
                  >
                    <option value="" disabled hidden className={""}>
                      Are you available for job?
                    </option>
                    {availabilityStatus?.map((item) => {
                      return (
                        <option
                          className="text-black"
                          key={item?.id}
                          value={item?.id}
                        >
                          {item?.title}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {errors["status"]?.message && (
                  <span className="whitespace-pre text-xs mt-2 text-orange-700">
                    {errors["status"]?.message}
                  </span>
                )}
              </div>
            );
          }}
        />
        <div className="h-6" />
        <CustomInput
          label={"Location"}
          name="location"
          placeholder={"Ex: United Kingdom"}
          rules={{
            required: "This field is required.",
          }}
          control={control}
          errors={errors}
        />
      </form>
    </div>
  );
});

export default Details;
