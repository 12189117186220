import React, { forwardRef, useEffect, useRef, useState } from "react";
import profile from "../../../assets/profilePic3.png";
import { useSelector } from "react-redux";
import ReferIcon from "../../../assets/svg/ReferIcon";
import TrackingIcon from "../../../assets/svg/TrackingIcon";
import LocationIconOutline from "../../../assets/svg/LocationIconOutline";
import ShareIconOutline from "../../../assets/svg/ShareIconOutline";
import LikeIconLeft from "../../../assets/svg/LikeIconLeft";
import { candidateStatus } from "../../../utils/constants";
import EditIcon from "../../../assets/svg/EditIcon";
import ChevronDownFilled from "../../../assets/svg/ChevronDownFilled";
import About from "./Components/About";
import Jobs from "./Components/Jobs";
import Staff from "./Components/Staff";
import Partner from "./Components/Partner";

const tabs = ["About", "Jobs", "Staff", "Partners", "Contact Details"];

const RenderBody = forwardRef(
  ({ activeTab, isEditing, setEditingTab, handleSave }, ref) => {
    switch (activeTab) {
      case 0:
        return <About />;
      case 1:
        return <Jobs />;
      case 2:
        return <Staff />;
      case 3:
        return <Partner />;
      default:
        return <div />;
    }
  }
);

const AgentProfile = () => {
  const formRef = useRef();
  const { user } = useSelector((state) => state.auth);
  const [imageContainerWidth, setImageContainerWidth] = useState(0);
  const imageContainerRef = useRef(null);
  const [activeTab, setActiveTab] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [editingTab, setEditingTab] = useState(null);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  useEffect(() => {
    if (imageContainerRef) {
      setImageContainerWidth(
        imageContainerRef?.current?.getBoundingClientRect().width / 2
      );
    }
  }, [imageContainerRef]);

  const handleSave = async (formData) => {
    console.log("formdata", formData);
  };

  return (
    <div className="flex bg-white flex-col flex-1 h-full overflow-hidden w-full shadow-[2px_2px_12px_0px_rgba(180,180,180,0.25)] rounded-lg p-4">
      <div className="flex items-center bg-[#FAFAFA] py-3.5 pl-3.5 pr-[18px] rounded-xl border border-[#F7F7F7] mb-1 relative">
        <div ref={imageContainerRef} className="absolute top-0">
          <img src={profile} className="rounded-full h-40 w-40" />
        </div>
        <div className="flex justify-between flex-1 gap-1 pl-44">
          <div className="flex flex-col gap-2">
            <span className="font-montserrat text-primary font-semibold text-2xl border-b border-primary w-max pb-1">
              {user?.firstName} {user?.lastName}
            </span>
            <span className="font-montserrat text-primary font-medium text-xs max-[900px]:w-min">
              {user?.jobTitle ?? "Recruitment Agent"}
            </span>
            <div className="flex items-center gap-0.5">
              <LocationIconOutline className={"w-3.5 h-3.5"} />
              <span className="font-montserrat text-light-black font-medium text-[9px] max-[900px]:w-min">
                {user?.location ?? "London"}
              </span>
              <EditIcon className="w-3.5 h-3.5 ml-1.5" />
            </div>
          </div>
          <div className="flex items-center self-start gap-2">
            <div className="w-[8px] h-[8px] bg-[#23BD33] rounded-full animate-pulse" />
            <span className="font-montserrat font-medium text-[10px] text-light-black">
              {candidateStatus[user?.status] ?? "Active agent"}
            </span>
            <ChevronDownFilled className="w-3.5 h-3.5" fill="#6f6f6f" />
          </div>
          <div className="flex justify-between pr-2.5 relative">
            <span className="font-montserrat text-light-black font-medium text-[11px] max-[900px]:w-min italic">
              {user?.refNo ?? "CA-FQ1478"}
            </span>
          </div>
        </div>
      </div>
      {/* Action button section */}
      <div className="flex">
        <div
          style={{
            width: imageContainerWidth + "px",
          }}
        />
        <div
          style={{
            borderBottom: "1px solid #f5f5f5",
          }}
          className="flex gap-24 justify-center pb-0.5 pl-30 flex-1"
        >
          <div className="group flex flex-col justify-between items-center cursor-pointer h-9 mt-1.5">
            <LikeIconLeft className="w-7 h-7 mt-0.5" fill={"#2E2E2E"} />
            <span className="font-medium uppercase text-[8px] text-light-black">
              Likes
            </span>
          </div>
          <div className="group flex flex-col justify-between items-center cursor-pointer h-9 mt-1.5">
            <ReferIcon className="w-6 h-6" />
            <span className="font-medium uppercase text-[8px] text-light-black">
              Refer
            </span>
          </div>
          <div className="group flex flex-col justify-between items-center cursor-pointer h-9 mt-1.5">
            <TrackingIcon className={"w-6 h-6"} fill={"#2E2E2E"} />
            <span className="font-medium uppercase text-[8px] text-light-black">
              Track
            </span>
          </div>
          <div className="group flex flex-col justify-between items-center cursor-pointer h-9 mt-1.5">
            <ShareIconOutline className="w-6 h-6" />
            <span className="font-medium uppercase text-[8px] text-light-black">
              Share
            </span>
          </div>
        </div>
      </div>
      {/* Main Body Wrapper */}
      <div className="pl-48 flex flex-col flex-1 overflow-hidden">
        {/* Tab section */}
        <div className="flex items-center justify-between pr-20 border-b mt-10">
          {tabs?.map((item, index) => {
            return (
              <button
                key={index}
                onClick={() => handleTabClick(index)}
                style={{
                  borderBottom:
                    activeTab === index
                      ? "2px solid #EFBF04"
                      : "2px solid transparent",
                }}
                className="pb-1 px-2.5"
              >
                <span className="font-montserrat text-primary font-medium text-xs">
                  {item}
                </span>
              </button>
            );
          })}
        </div>
        <div className="flex-1 overflow-y-auto my-7 no-scrollbar">
          <RenderBody
            ref={formRef}
            activeTab={activeTab}
            isEditing={isEditing}
            editingTab={editingTab}
            handleSave={handleSave}
          />
        </div>
      </div>
    </div>
  );
};

export default AgentProfile;
