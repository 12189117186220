import { useForm } from "react-hook-form"
import CustomInput from "../../components/CustomInput/CustomInput"
import regexList from "../../utils/regexList"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { signup } from "../../endpoints/auth.service"
import { useDispatch } from "react-redux"
import { setUserData } from "../../store/actions/auth.action"
import { useSnackbar } from "notistack"
import TickOutline from "../../assets/svg/TickOutline"


const RegisterPage = () => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const {
        register,
        handleSubmit,
        control,
        formState: { errors }
    } = useForm({

    })

    const [keepMeSignedIn, setKeepMeSignedIn] = useState(false)
    const [selectedRole, setSelectedRole] = useState('employer')

    const handleRegister = (data) => {
        signup({ ...data, userType: selectedRole === 'employer' ? '2' : '3' }).then((res) => {
            dispatch(setUserData(res?.data));
            navigate('/email-verification', {
                state: {
                    email: data?.email,
                    isSignup: true
                }
            })
        }).catch((err) => {
            enqueueSnackbar(err, { variant: "error" });
        })
    }

    const handleRoleChange = () => {
        setSelectedRole((prev) => {
            if (prev === 'employer') {
                return 'candidate'
            } else {
                return 'employer'
            }
        })
    }

    return <div className="flex flex-col items-center justify-center min-h-screen py-10">
        <div className="flex flex-col justify-center items-center border-[#DDDDDD] border rounded-lg py-10 px-20 relative bg-white">
            <button
                onClick={handleRoleChange}
                className="font-medium underline text-secondary mb-2 ml-auto absolute -top-8 right-1">{selectedRole === 'employer' ? 'Are you looking for job?' : 'Are you an employer?'}</button>
            <label className="font-semibold text-3xl mb-2">Register {selectedRole === 'employer' ? 'as an employer' : ''}</label>
            <label className="text-light-black font-medium px-10">Create your account today and get started for free!</label>
            <form
                className="flex flex-col w-full pt-10"
                onSubmit={handleSubmit(handleRegister)}
            >
                <div className="flex items-center justify-center gap-3">
                    <CustomInput
                        label={'First Name'}
                        placeholder={'Enter your First Name'}
                        name="firstName"
                        rules={{
                            required: "This field is required.",
                        }}
                        control={control}
                        errors={errors}
                    />
                    <CustomInput
                        label={'Last Name'}
                        placeholder={'Enter your Last Name'}
                        name="lastName"
                        rules={{
                            required: "This field is required.",
                        }}
                        control={control}
                        errors={errors}
                    />
                </div>
                <div className="h-3" />
                {
                    selectedRole === 'employer' ?
                        <>
                            <CustomInput
                                label={'Company Name'}
                                placeholder={'Enter your Company Name'}
                                name="companyName"
                                rules={{
                                    required: "This field is required.",
                                }}
                                control={control}
                                errors={errors}
                            />
                            <div className="h-3" />
                        </>
                        :
                        null
                }
                <CustomInput
                    label={'Email'}
                    placeholder={'Enter your Email'}
                    name="email"
                    rules={{
                        required: "This field is required.",
                        pattern: {
                            value: regexList.email,
                            message: "Please enter a valid email."
                        }
                    }}
                    control={control}
                    errors={errors}
                />
                <div className="h-3" />
                <CustomInput
                    label={'Confirm Email'}
                    placeholder={'Enter your Confirm Email'}
                    name="confirmEmail"
                    rules={{
                        required: "This field is required.",
                        pattern: {
                            value: regexList.email,
                            message: "Please enter a valid email."
                        }
                    }}
                    control={control}
                    errors={errors}
                />
                <div className="h-3" />
                <div className="flex items-center justify-center gap-3">
                    <CustomInput
                        label={'Password'}
                        placeholder={'Enter your Password'}
                        name="password"
                        rules={{
                            required: "This field is required.",
                            pattern: {
                                value: regexList.password,
                                message: "Please enter a valid password.",
                            },
                        }}
                        control={control}
                        errors={errors}
                    />
                    <CustomInput
                        label={'Confirm Password'}
                        placeholder={'Enter your Password'}
                        name="confirmPassword"
                        rules={{
                            required: "This field is required.",
                            pattern: {
                                value: regexList.password,
                                message: "Please enter a valid password.",
                            },
                        }}
                        control={control}
                        errors={errors}
                    />
                </div>

                {/* Checkbox container  */}
                <div className="flex items-center gap-2 mt-3">
                    <div
                        onClick={() => setKeepMeSignedIn((prev) => !prev)}
                        className="border-2 border-[#6F6F6F] h-4 w-4 flex items-center justify-center cursor-pointer">
                        {
                            keepMeSignedIn ?
                                <TickOutline className='w-1.5 h-1.5' fill="#6F6F6F" />
                                :
                                null
                        }
                    </div>
                    <span className="font-montserrat font-medium text-primary text-sm">I agree with <span className="underline">Terms of Use</span> and <span className="underline">Privacy Policy</span></span>
                </div>

                <button
                    type="submit"
                    className="bg-secondary rounded-full mt-10 py-3"
                >
                    <span className="text-white font-medium">Register</span>
                </button>
                <span className="mx-auto text-sm mt-1 font-medium text-light-black">Already have an account? <span onClick={() => navigate('/login')} className="text-secondary cursor-pointer">Login</span></span>
            </form>
        </div>
    </div>
}

export default RegisterPage