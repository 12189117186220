import React, { useEffect, useState } from "react";
import CustomInput from "../../../components/CustomInput/CustomInput";
import { useForm } from "react-hook-form";
import {
  addCertificateOrEducation,
  getCertificateOrEducation,
  updateCertificateOrEducation,
} from "../../../endpoints/education.service";
import { useSnackbar } from "notistack";
import moment from "moment";

const EducationInput = ({ education, customId, setEducations }) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { isDirty, errors },
  } = useForm();

  const [editable, setEditable] = useState(false);

  const handleSaveEducation = (data) => {
    const payload = data;
    payload.type = 1;
    if (education?._id) {
      payload.id = education?._id;
      updateCertificateOrEducation(payload).then((response) => {
        const { data, success } = response;
        if (success) {
          setEducations((prev) => {
            return prev?.map((edu) => {
              if (edu?._id === education?._id) {
                return { isEditing: false, ...data };
              } else {
                return edu;
              }
            });
          });
          enqueueSnackbar("Education udpated successfully.", {
            variant: "success",
          });
        }
      });
    } else {
      delete payload._id;
      delete payload.id;
      addCertificateOrEducation(payload).then((response) => {
        const { data, success } = response;
        if (success) {
          setEducations((prev) => {
            return prev?.map((exp, index) => {
              if (index === customId) {
                return { isEditing: false, ...data };
              } else {
                return exp;
              }
            });
          });
          enqueueSnackbar("Education added successfully.", {
            variant: "success",
          });
        }
      });
    }
  };

  useEffect(() => {
    setEditable(
      education?.isEditing !== undefined ? education?.isEditing : false
    );
  }, [education?.isEditing]);

  useEffect(() => {
    Object.entries(education)?.forEach(([key, value]) => {
      setValue(key, value);
    });
  }, [education]);

  return (
    <div
      className={`flex flex-col ${
        editable ? "border rounded-md px-8 py-4" : ""
      }`}
    >
      <p
        className={`text-[#6f6f6f] text-xs font-medium cursor-pointer flex justify-end ${
          !editable ? "mt-0" : ""
        }`}
        onClick={
          editable ? handleSubmit(handleSaveEducation) : () => setEditable(true)
        } // Toggle editing state
      >
        {editable ? (education?._id ? "Update" : "Save") : "Edit"}
      </p>
      {!editable ? (
        <div className="flex flex-col gap-1">
          <span className="font-semibold text-sm text-primary">
            {education?.degree} {education?.fieldOfStudy}
          </span>
          <span className="font-medium text-xs text-light-black">
            {education?.school} - {education?.location}&nbsp;&nbsp;
            <span className="font-normal text-xs text-light-black">
              ( {moment(education?.startDate).format("MMM YYYY")} -
              {moment(education?.endDate).format("MMM YYYY")} )
            </span>
          </span>
        </div>
      ) : (
        <form className="grid grid-cols-2 gap-4">
          <CustomInput
            label={"Degree"}
            name="degree"
            placeholder={"Ex: Bachelor's"}
            rules={{
              required: "This field is required.",
            }}
            control={control}
            errors={errors}
          />
          <CustomInput
            label={"Field of Study"}
            name="fieldOfStudy"
            placeholder={"Ex: Business"}
            rules={{
              required: "This field is required.",
            }}
            control={control}
            errors={errors}
          />
          <CustomInput
            label={"School"}
            name="school"
            placeholder={"Ex: Boston University"}
            rules={{
              required: "This field is required.",
            }}
            control={control}
            errors={errors}
          />
          <CustomInput
            label={"Location"}
            name="location"
            placeholder={"Ex: London, UK"}
            rules={{
              required: "This field is required.",
            }}
            control={control}
            errors={errors}
          />
          <CustomInput
            type="date"
            label={"Start Date"}
            name="startDate"
            placeholder={"Ex: Project Manager"}
            rules={{
              required: "This field is required.",
            }}
            control={control}
            errors={errors}
          />
          <CustomInput
            type="date"
            label={"End Date"}
            name="endDate"
            placeholder={"Ex: Project Manager"}
            rules={{
              required: "This field is required.",
            }}
            control={control}
            errors={errors}
          />
        </form>
      )}
    </div>
  );
};

const Education = ({ isEditing }) => {
  const [educations, setEducations] = useState([
    {
      degree: "",
      fieldOfStudy: "",
      school: "",
      location: "",
      startDate: "",
      endDate: "",
      isEditing: true,
    },
  ]);
  const [certificates, setCertificates] = useState([{}]);

  const handleAddEducation = () => {
    setEducations((prev) => {
      return [
        {
          degree: "",
          fieldOfStudy: "",
          school: "",
          location: "",
          startDate: "",
          endDate: "",
          isEditing: true,
        },
        ...prev,
      ];
    });
  };

  const handleAddCertificate = () => {};

  useEffect(() => {
    getCertificateOrEducation().then((response) => {
      const { success, data } = response;
      if (success) {
        setEducations(data?.educations);
      }
    });
  }, []);

  return (
    <div className="flex flex-col">
      <div className={`flex flex-col items-start gap-3 mb-8`}>
        <span className="font-montserrat text-[#383838] font-bold text-sm">
          Education & Certifications
        </span>
        <div className="flex flex-col gap-6 w-[90%] mt-2">
          {educations?.map((education, index) => {
            return (
              <EducationInput
                key={index}
                customId={index}
                education={education}
                setEducations={setEducations}
              />
            );
          })}
        </div>
        <button
          onClick={handleAddEducation}
          className="text-sm border py-1 px-2 text-[#6f6f6f] rounded font-semibold"
        >
          + Add Education
        </button>
        <button
          onClick={handleAddCertificate}
          className="text-sm border py-1 px-2 text-[#6f6f6f] rounded font-semibold"
        >
          + Add Certificate
        </button>
      </div>
    </div>
  );
};

export default Education;
