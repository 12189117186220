import { Controller, useForm } from "react-hook-form";
import SaveIcon from "../../../assets/svg/SaveIcon";
import TrashIcon from "../../../assets/svg/TrashIcon";
import CustomInput from "../../../components/CustomInput/CustomInput";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import { skillsData } from "../dummyData";
import CustomSlider from "../../../components/CustomSlider/CustomSlider";
import { useCallback, useEffect } from "react";
import { useSnackbar } from "notistack";
import EditIcon from "../../../assets/svg/EditIcon";
import { JobSkills, skillsUsed } from "../../../utils/constants";
import { addSkill, updateSkill } from "../../../endpoints/candidate.service";
import { getSkills } from "../../../endpoints/experience.service";

const skillsLastUsed = Object.entries(skillsUsed).map(([key, value]) => ({
  title: key,
  id: value,
}));

export const SkillInput = ({
  customSkillId,
  data,
  addUpdateSkill,
  index,
  deleteSkill,
  editSkill,
  onEditSkill,
  workExperienceId,
  setEditable,
  setExperiences,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    watch,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      ...data,
      lastUsed: data?.lastUsed ?? 1,
      rating: 0,
    },
  });
  const field = watch();

  const onSave = (data) => {
    if (!workExperienceId) {
      enqueueSnackbar("Please to save your experience before saving skills.", {
        variant: "error",
      });
      return;
    }
    const payload = {
      ...data,
      workExperienceId,
      lastUsed: parseInt(data?.lastUsed),
      years: parseInt(data?.years),
      type: 1,
      userType: 1,
    };
    addSkill(payload).then((response) => {
      const { success, data } = response;
      if (success) {
        setEditable(false);
        setExperiences((prev) => {
          return prev?.map((exp) => {
            if (exp?._id === workExperienceId) {
              return {
                ...exp,
                skills: [data, ...exp?.skills],
                // skills: exp?.skills?.map((skillItem, skillItemIndex) => {
                //   if (skillItemIndex === customSkillId) {
                //     return data;
                //   } else {
                //     return skillItem;
                //   }
                // }),
              };
            } else {
              return exp;
            }
          });
        });
        enqueueSnackbar("Skills saved successfully.", { variant: "success" });
      }
    });
  };

  if (data?.isSaved) {
    return (
      <>
        <div className="flex items-center my-4">
          <label
            style={{ width: "20%" }}
            className="flex font-medium text-xs text-primary"
          >
            {data?.title}
          </label>
          <label
            style={{ width: "25%" }}
            className="flex font-medium text-xs text-primary"
          >
            <ProgressBar value={data?.rating} total={10} />
          </label>
          <label
            style={{ width: "40%" }}
            className="flex font-medium text-xs text-primary justify-center"
          >
            {data?.years}
          </label>
          <label
            style={{ width: "15%" }}
            className="flex font-medium text-xs text-primary"
          >
            {Object.keys(skillsUsed).find(
              (key) => skillsUsed[key] === data?.lastUsed
            )}{" "}
          </label>
          {data?.isEdit && (
            <div className="flex w-[5%] mr-5 items-end gap-3">
              <div
                className="cursor-pointer"
                onClick={() => onEditSkill(index)}
              >
                <EditIcon className={"w-4 h-4"} />
              </div>
              <div
                className="cursor-pointer"
                onClick={() => deleteSkill(index)}
              >
                <TrashIcon className={"w-4 h-4"} />
              </div>
            </div>
          )}
        </div>
        <div className="w-full border-t border-[#DDDDDDDD] my-2"></div>
      </>
    );
  }
  return (
    <form className="flex items-center px-4 py-3 bg-light-white-700 rounded-lg mb-5">
      <div
        style={{ width: "25%" }}
        className="flex flex-col  text-sm text-primary"
      >
        <Controller
          control={control}
          name="title"
          rules={{
            required: "This field is required.",
          }}
          render={({ field: { value, onChange } }) => (
            <input
              className="font-normal rounded-md bg-white px-3 py-2 text-xs w-[85%]"
              placeholder="Javascript"
              value={value}
              onChange={(c) => onChange(c.target.value)}
            />
          )}
        />
        {errors?.title?.message && (
          <span className="whitespace-pre text-xs mt-2 text-orange-700">
            {errors?.title?.message}
          </span>
        )}
      </div>
      <div
        style={{ width: "25%" }}
        className="flex flex-col  text-sm text-primary"
      >
        <Controller
          control={control}
          name="rating"
          rules={{
            required: "This field is required.",
          }}
          render={({ field: { value, onChange } }) => (
            <CustomSlider
              step={1}
              min={0}
              max={10}
              fillerClass={`h-[5px]`}
              defaultValue={5}
              value={value}
              labelStyle={`text-xs`}
              onChange={onChange}
            />
          )}
        />
        {errors?.rating?.message && (
          <span className="whitespace-pre text-xs mt-2 text-orange-700">
            {errors?.rating?.message}
          </span>
        )}
      </div>
      <label
        style={{ width: "25%" }}
        className="flex flex-col font-semibold text-sm text-primary items-center justify-center"
      >
        <Controller
          control={control}
          name="years"
          rules={{
            required: "This field is required.",
          }}
          render={({ field: { value, onChange } }) => (
            <input
              className="font-normal rounded-md bg-white px-3 py-2 text-xs w-1/2"
              placeholder="9"
              value={value}
              onChange={(c) => onChange(c.target.value)}
            />
          )}
        />
        {errors?.years?.message && (
          <span className="whitespace-pre text-xs mt-2 text-orange-700">
            {errors?.years?.message}
          </span>
        )}
      </label>
      <div style={{ width: "20%" }} className="flex text-sm text-primary">
        <div className="w-full">
          <Controller
            name={"lastUsed"}
            control={control}
            rules={{
              required: "This field is required.",
            }}
            defaultValue={skillsUsed["Current"]}
            // defaultValue=""
            render={({ field }) => {
              return (
                <div className={`flex flex-col w-full`}>
                  <div
                    className={`rounded-lg py-2.5 px-3 font-montserrat font-medium text-xs flex items-center border`}
                  >
                    <select
                      {...field}
                      className="w-full outline-none bg-transparent focus:outline-none focus:border-blue-500 text-primary"
                      name={"lastUsed"}
                      id={"lastUsed"}
                    >
                      {skillsLastUsed?.map((item) => {
                        return (
                          <option
                            className="text-black"
                            key={item?.id}
                            value={item?.id}
                          >
                            {item?.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {errors["lastUsed"]?.message && (
                    <span className="whitespace-pre text-xs mt-2 text-orange-700">
                      {errors["lastUsed"]?.message}
                    </span>
                  )}
                </div>
              );
            }}
          />
        </div>
      </div>
      <div className="flex flex-col w-[5%] items-end gap-3">
        <div className="cursor-pointer" onClick={handleSubmit(onSave)}>
          <SaveIcon className={"w-4 h-4"} />
        </div>
        <div className="cursor-pointer" onClick={() => deleteSkill(index)}>
          <TrashIcon className={"w-4 h-4"} />
        </div>
      </div>
    </form>
  );
};

const Skills = ({ isEditing }) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      skills: skillsData,
    },
  });
  const field = watch();
  useEffect(() => {
    if (isEditing) {
      const tempSkills = field?.skills?.map((x) => ({
        ...x,
        isEdit: true,
      }));
      setValue("skills", [...tempSkills]);
      return;
    } else {
      const tempSkills = field?.skills?.map((x) => ({
        ...x,
        isEdit: false,
      }));
      setValue("skills", [...tempSkills]);
    }
  }, [isEditing]);

  const onEditSkill = (index) => {
    let tempSkills = field.skills;
    tempSkills[index].isSaved = false;
    setValue("skills", [...tempSkills]);
  };

  const addNewskill = () => {
    setValue("skills", [{ isSaved: false, isEdit: true }, ...field?.skills]);
  };

  const addUpdateSkill = useCallback(
    async (data, index) => {
      let tempSkills = field.skills;
      tempSkills[index].isSaved = true;
      setValue("skills", [...tempSkills]);
      // return;
      try {
        let response;
        const lastUsed = JobSkills.find((x) => x.label === data?.lastUsed);
        if (data?._id) {
          response = await updateSkill({
            id: data?._id,
            title: data?.skill,
            rating: data?.level,
            years: data?.experience,
            lastUsed: lastUsed?.value,
          });
        } else {
          response = await addSkill({
            title: data?.skill,
            rating: data?.level,
            years: data?.experience,
            lastUsed: lastUsed?.value,
            jobId: createdDraftJob?._id,
            type: 2,
            userType: 2,
          });
        }

        if (response?.success) {
          let newSkills = field.skills;
          newSkills[index] = {
            ...data,
            ...response?.data,
            isEdit: false,
            isSaved: true,
          };
          setValue("skills", [...newSkills]);
        }
      } catch (error) {
        enqueueSnackbar(error, { variant: "error" });
      }
    },
    [field]
  );

  const deleteSkillPress = async (index) => {
    try {
      const skills = field.skills[index];
      // if (skill?.isSaved === false) {
      let n = field?.skills;
      n.splice(index, 1);
      setValue("skills", [...n]);
      return;
      // }
      const data = await deleteSkill(skill?._id);

      if (data?.success) {
        let n = field?.skills;
        n.splice(index, 1);
        setValue("skills", [...n]);
        return;
      }
    } catch (error) {
      enqueueSnackbar(error, { variant: "error" });
    }
  };

  const editSkill = useCallback(
    async (index) => {
      let newSkills = field.skills;
      newSkills[index] = {
        ...newSkills[index],
        isEdit: true,
        isSaved: false,
      };
      setValue("skills", [...newSkills]);
    },
    [field]
  );

  useEffect(() => {
    getSkills().then(({ success, data }) => {
      if (success) {
        setValue(
          "skills",
          data?.map((item) => {
            return { ...item, isSaved: true };
          })
        );
      }
    });
  }, []);

  return (
    <div>
      {isEditing && (
        <button
          className="text-sm border border-gray-400 py-2 px-3 text-[#6f6f6f] rounded font-semibold"
          onClick={addNewskill}
        >
          + Add Skills
        </button>
      )}
      <div className="h-full flex flex-1 flex-col pt-7 overflow-y-scroll no-scrollbar">
        <div className="flex items-center pb-3">
          <label
            style={{ width: "20%" }}
            className="flex font-bold text-sm text-primary"
          >
            Skill
          </label>
          <label
            style={{ width: "25%" }}
            className="flex font-bold text-sm text-primary"
          >
            Level out of 10
          </label>
          <label
            style={{ width: "40%" }}
            className="flex font-bold text-sm text-primary justify-center"
          >
            Years of experience
          </label>
          <label
            style={{ width: "15%" }}
            className="flex font-bold text-sm text-primary"
          >
            Last used
          </label>
          {isEditing && (
            <label
              style={{ width: "8%" }}
              className="flex font-bold text-sm text-primary"
            />
          )}
        </div>
        {field?.skills.map((skill, index) => (
          <SkillInput
            data={skill}
            key={index + new Date()}
            addUpdateSkill={addUpdateSkill}
            index={index}
            deleteSkill={deleteSkillPress}
            editSkill={editSkill}
            onEditSkill={onEditSkill}
          />
        ))}
      </div>
    </div>
  );
};

export default Skills;
