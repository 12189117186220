import {
  REMOVE_TOKEN,
  LOGIN_USER,
  SET_AUTH_LOADING,
  AUTH_USER_UPDATE,
  SET_USER_DATA,
} from "./action.types";
import { refreshTokenService } from "../../endpoints/auth.service";

export const removeToken = () => {
  return {
    type: REMOVE_TOKEN,
  };
};

export const tokenValidateSuccess = (payload) => {
  return {
    type: LOGIN_USER,
    payload: payload,
  };
};

export const refreshToken = (token) => async (dispatch) => {
  try {
    const { data } = await refreshTokenService(token);
    dispatch({
      type: LOGIN_USER,
      payload: {
        user: data.user,
        token: data.token,
        refreshToken: data.refreshToken,
      },
    });
    return data;
  } catch (err) {
    console.error(err);
    dispatch(removeToken());
    return null;
  }
};

export const setUserData = (payload) => {
  return {
    type: SET_USER_DATA,
    payload: payload,
  };
};

export const authUserUpdate = (user) => {
  return {
    type: AUTH_USER_UPDATE,
    payload: user,
  };
};

export const setAuthLoading = (status) => {
  return {
    type: SET_AUTH_LOADING,
    payload: status,
  };
};
