import { Link } from "react-router-dom";

const JobCard = () => {
  return (
    <Link
    to={'detail'}
    className="rounded-xl border p-4 hover:bg-light-white-700">
      <div className="flex items-center justify-between">
        <span className="font-semibold text-primary text-sm">
          Junior IT Analyst
        </span>
        <span className="font-semibold text-xs text-light-black">11h ago</span>
      </div>
      <span className="font-medium text-primary text-sm">
        Body Corporate Brokers
      </span>

      <div className="flex flex-col my-2.5">
        <span className="font-medium text-primary text-sm">Sydney NSW</span>
        <span className="font-medium text-primary text-sm">
          Help Desk & IT Support{" "}
          <span className="text-xs">
            (Information & Communication Technology)
          </span>
        </span>
      </div>

      <p className="font-medium text-xs text-light-black">
        Join us as a Compliance Officer and receive :- A competitive salary -
        Lots of learning opportunities - A supportive team - And much more!
      </p>
    </Link>
  );
};

export default JobCard;
